<template>
    <v-col cols="12" md="12">
        <v-card class="mt-5">
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                    >Modificar usuario y contraseña</v-toolbar-title
                >
            </v-toolbar>
            <v-form @submit.prevent="submit">
                <v-card-text>
                    <div>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-checkbox
                                    v-model="form.changepassword"
                                    label="¿Cambiar contraseña?"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-show="form.changepassword">
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.name.$model"
                                    :error-messages="nameErrors"
                                    label="Nombre de usuario*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    :append-icon="showcurrentpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :append-focusable="false"
                                    :type="showcurrentpassword ? 'text' : 'password'"
                                    v-model="$v.form.currentpassword.$model"
                                    :error-messages="currentpasswordErrors"
                                    label="Contraseña actual*"
                                    @click:append="showcurrentpassword = !showcurrentpassword"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    :append-icon="shownewpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="shownewpassword ? 'text' : 'password'"
                                    v-model="$v.form.newpassword.$model"
                                    :error-messages="newpasswordErrors"
                                    label="Nueva contraseña*"
                                    @click:append="shownewpassword = !shownewpassword"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    :append-icon="showconfirmnewpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showconfirmnewpassword ? 'text' : 'password'"
                                    v-model="$v.form.confirmnewpassword.$model"
                                    :error-messages="confirmnewpasswordErrors"
                                    label="Confirmar nueva contraseña*"
                                    @click:append="showconfirmnewpassword = !showconfirmnewpassword"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-4"
                        color="primary"
                        type="submit"
                        elevation="0"
                        :loading="loading"
                        >Cambiar</v-btn
                    >
                </v-card-actions>
            </v-form>
        </v-card>
    </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { mapMutations, mapState } from "vuex";
export default {
    mixins: [validationMixin],
    props: {
        item: Object,
    },
    validations: {
        form: {
            changepassword: {},
            name: { required, minLength: minLength(5) },
            currentpassword: { required },
            newpassword: { required },
            confirmnewpassword: { required },
        },
    },
    data() {
        return {
            showcurrentpassword: false,
            shownewpassword: false,
            showconfirmnewpassword: false,
            form: {
                changepassword: "",
                name: this.item.name,
                currentpassword: "",
                newpassword: "",
                confirmnewpassword: "",
            },
        };
    },
    watch: {
        item() {
            this.form.name = this.item.name
        },
    },
    mounted() {},
    computed: {
        ...mapState(["loading"]),
        nameErrors() {
            const errors = [];
            if (!this.$v.form.name.$dirty) return errors;
            !this.$v.form.name.minLength &&
                errors.push(
                    "El nombre de usuario debe contener al menos 5 caracteres"
                );
            !this.$v.form.name.required && errors.push("Obligatorio");
            return errors;
        },
        currentpasswordErrors() {
            const errors = [];
            if (!this.$v.form.currentpassword.$dirty) return errors;
            !this.$v.form.currentpassword.required && errors.push("Obligatorio");
            return errors;
        },
        newpasswordErrors() {
            const errors = [];
            if (!this.$v.form.newpassword.$dirty) return errors;
            !this.$v.form.newpassword.required && errors.push("Obligatorio");
            return errors;
        },
        confirmnewpasswordErrors() {
            const errors = [];
            if (!this.$v.form.confirmnewpassword.$dirty) return errors;
            !this.$v.form.confirmnewpassword.required && errors.push("Obligatorio");
            return errors;
        },
    },
    methods: {
        ...mapMutations(["setLoading"]),
        submit() {
            // console.log("1");
            this.$v.$touch();
            // console.log("2");
            if (!this.$v.$invalid) {
                // console.log("3");
                this.loading = true;
                this.$emit("doSave", this.form);
            }
        },
    },
};
</script>

<style>
</style>