<template>
    <v-container fluid>
        <form-salidas></form-salidas>
    </v-container>
</template>

<script>
import FormSalidas from "@/views/salidas/Form.vue";

export default {
    components: {
        FormSalidas,
    },
    /* props: {
        items: {
            type: Array,
        },
        itemText: {
            type: String,
            default: "name",
        },
        itemId: {
            type: String,
            default: "id",
        },
    }, */
    data() {
        return {
           
        };
    },
};
</script>

<style>
</style>