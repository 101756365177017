<template>
    <v-row justify="center">
        <v-btn
            block
            :color="selectedItem || value ? 'success' : 'primary'"
            :disabled="disabled"
            elevation="0"
            @click.stop="openDialog"
        >
            {{ selectedItem ? selectedItem : value ? value[itemText] : buttonText }} 
        </v-btn>
        <modal-component
            :active="dialog"
            max-width="1500px"
            @close="closeDialog"
        >
            <v-card>
                <v-toolbar dark elevation="0" color="primary">
                    <v-toolbar-title>{{ buttonText }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small fab elevation="0" @click="closeDialog">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                </v-toolbar>
                <custom-search-component :items="items" :itemText="itemText" @change="selectItem($event)">
                </custom-search-component>
            </v-card>
        </modal-component>
    </v-row>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import CustomSearchComponent from "@/components/CustomSearchComponent.vue";
export default {
    components: {
        ModalComponent,
        CustomSearchComponent
    },
    props: {
        value: {},
        buttonText: {
            type: String,
            default: "Seleccionar",
        },
        items: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        /* query: {
            type: Object,
        }, */
        /* select: {
            type: String,
            default: null
        }, */
        /* skip: {
            type: Boolean,
            default: false
        }, */
        /* variable: {
            type: String,
            default: null,
        }, */
        itemText: {
            type: String,
            default: "name",
        },
        itemId: {
            type: String,
            default: "id",
        },
    },
    data() {
        return {
            selectedItem: null,
            dialog: null,
            /* items: null, */
            
        };
    },
    watch: {
        value() {//oldVal, newVal
            //console.log("W value", oldVal, newVal);
            this.updateSelectedItem();
            //this.checkValue();
        },
        items() {
            if (!this.items || !this.items.length) return;
            if (!this.selectedItem) {
                this.updateSelectedItem();
            }
            //console.log("items", this.items);
            /* this.items.forEach((item) => {
                if (!this.alpha.includes(item[this.itemText].charAt(0))) {
                    this.alpha.push(item[this.itemText].charAt(0));
                }
            }); */
            //this.checkValue();
        },
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        updateSelectedItem() {
            console.log("value", this.value);
            if(this.value){
               var item = this.items.find((it) => it.id == this.value[this.itemId]);
            }
            if (item) {
                this.selectedItem = item[this.itemText];
                this.$emit("input", item);
                //this.$emit("change", item[this.itemId]);
            } else {
                this.selectedItem = null;
            }
        },
        checkValue() {
            // console.log("checkValue", this.items, this.value);
            if (!this.value) {
                this.selectedItem = null;
            }
            if (
                this.items &&
                this.items.length &&
                this.value != this.selectedItem
            ) {
                var item = this.items.find((it) => it.id == this.value[this.itemId]);
                if (item) {
                    this.selectItem(item);
                }
            }
        },
        selectItem(item) {
            // console.log("selectItem", item);
            this.selectedItem = item[this.itemText];
            this.$emit("input", item);
            this.$emit("change", item[this.itemId]);
            this.closeDialog();
        },
    },
    /* apollo: {
        items: {
            query() {
                return this.query;
            },
            variables() {
                if (this.variable != null) {
                    return {
                        id: this.variable,
                    };
                }
            },
            skip(){
                console.log("skip", this.variable);
                return this.skip == true && this.variable == null;
            }
        },
    }, */
};
</script>

<style>
</style>
