<template>
    <v-card outlined class="ma-2">
        <v-row>
            <v-col cols="3">
                <router-link
                    v-if="haveRole('outputedit')"
                    :to="{ name: 'salidas' }"
                >
                    <v-btn elevation="0" color="primary">Listado</v-btn>
                </router-link>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4">
                <v-text-field
                    v-model="searchText"
                    hide-details=""
                    dense
                    outlined
                    label="Buscador"
                >
                </v-text-field>
            </v-col>

            <v-col cols="auto" class="text-center">
                <v-btn elevation="0" color="warning" @click="removeLetter"
                    >Borrar</v-btn
                >
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn
                    class="mr-1 mb-3 pa-1"
                    dark
                    color="teal"
                    elevation="0"
                    style="font-size: 25px"
                    min-width="50"
                    v-for="(letter, i) in alpha"
                    :key="i"
                    @click="addLetter(letter)"
                >
                    <span class="font-weight-bold">{{ letter }}</span></v-btn
                >
            </v-col>
        </v-row>
        <v-divider class="mb-5"></v-divider>

        <v-row class="ma-0">
            <v-card
                class="ma-1"
                outlined
                elevation="0"
                rounded="lg"
                style="height: fit-content"
                v-for="item in itemsSearched"
                :key="item.id"
                @click="selectItem(item)"
            >
                <v-card-title
                    class="fondo-grid-item-title white--text text-uppercase"
                    style="word-break: break-word"
                    :class="item.favourite ? 'yellow--text' : ''"
                >
                    {{ item[itemText] }}
                </v-card-title>
            </v-card>
            <v-col>
                <p
                    v-if="!itemsSearched.length"
                    class="text-body-1 font-weight-bold text-center"
                >
                    No hay datos disponibles
                </p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        items: {
            type: Array,
        },
        itemText: {
            type: String,
            default: "name",
        },
    },
    data: () => ({
        searchText: "",
        alpha: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "Ñ",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
        ],
    }),
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        itemsSearched() {
            if (this.searchText == "") {
                return this.items;
            } else if (this.items) {
                return this.items.filter((it) =>
                    it[this.itemText]
                        .toLowerCase().startsWith(this.searchText.toLowerCase())
                        //.includes(this.searchText.toLowerCase())
                );
            }
            return [];
        },
    },
    methods: {
        addLetter(letter) {
            // console.log("add", letter);
            this.searchText = this.searchText.concat(letter);
        },
        removeLetter() {
            this.searchText = this.searchText.slice(0, -1);
        },
        selectItem(item) {
            //this.$emit("input", item[this.itemText]);
            this.$emit("change", item);
            //this.$emit("close");
        },
    },
};
</script>

<style>
</style>