import Vue from "vue";
export function setAccessToken(state, token) {
	state.accessToken = token;
}

export function setUser(state, user) {
	state.user = user;
	state.isLogged = true;
	state.error = false;
	state.errorMessage = "";

	//Setteamos los roles
	user.roles.forEach((role) => {
		Vue.set(state.roles, role, true);
	});
}

export function logout(state) {
	// console.log("mutation logout");
	state.accessToken = null;
	state.user = null;
    state.isLogged = false;
    state.roles = {}
    state.permissions = {}
    //location.reload();
}

export function authError(state, error) {
	state.error = true;
	state.errorMessage = error;
	state.user = null;
    state.isLogged = false;
    state.roles = {}
    state.permissions = {}
}
