<template>
    <v-container fluid>
        <title-component
            title="Catálogo"
            pathCreate="nuevoProducto"
            roleCreate="productedit"
        ></title-component>

        <filter-component @search="search"></filter-component>

        <grid-component
            :list="listProducts"
            gridType="productos"
            editPath="editarProducto"
        ></grid-component>
        <infinite-loading
            ref="infiniteLoading"
            @infinite="getInfiniteProducts"
            class="mt-5"
        >
            <div slot="no-more">No hay más elementos</div>
        </infinite-loading>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import GridComponent from "@/components/GridComponent.vue";

import { mapMutations } from "vuex";

export default {
    name: "Productos",
    components: {
        GridComponent,
        FilterComponent,
        TitleComponent,
    },
    data() {
        return {
            listProducts: [],
            params: {
                actualPage: 1,
                take: 20,
                favourite: null,
                ground: null,
                isMixed: null,
            },
            sheet: false,
        };
    },
    mounted() {},
    methods: {
        ...mapMutations(["setLoading"]),
        async getProducts() {
            try {
                const { data } = await this.$http.get("/products", {
                    params: this.params,
                });
                this.listProducts = this.listProducts.concat(data);
                return data;
            } catch (error) {}
        },
        async getInfiniteProducts($state) {
            try {
                // console.log($state);
                var items = await this.getProducts();
                $state.loaded();
                if (items.length == 0) $state.complete();
                else this.params.actualPage++;
            } catch (error) {}
        },
        async search() {
            try {
                this.listProducts = [];
                this.params.actualPage = 1;
                await this.getProducts();
                this.params.actualPage++;
            } catch (error) {}
        },
        async clearFilters() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.listProducts = [];
                this.params = {
                    actualPage: 1,
                    take: 20,
                    search: "",
                    favourite: null,
                    ground: null,
                    isMixed: null,
                };
            } catch (error) {}
        },
    },
};
</script>

<style>
</style>