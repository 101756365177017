import Vue from "vue";
import axios from "axios";
import store from "../store"
import router from "../router";

//const baseURL = "https://backend.especiascaperucitaroja.com";
const baseURL = process.env.VUE_APP_BACKEND_ECR;

axios.defaults.baseURL = baseURL;
/* axios.defaults.headers = {
    //'Cache-Control': 'no-cache'
    "Access-Control-Allow-Origin": "*"
} */

axios.interceptors.request.use((config) => {
	config.headers.authorization = `Bearer ${store.state.auth.accessToken}`;

	return config;
});

axios.interceptors.response.use(
	(response) => {
		if (response.status === 401) {
			alert("You are not authorized");
		}
		return response;
	},
	(error) => {
        console.log("prueba", error.response);
		if (error.response && error.response.data) {
            if(error.response.data.message){
                Vue.$toast.warning("Error: "+error.response.data.message);
            }else{
                Vue.$toast.warning("Error: "+error.response.data.error);
            }
            if(error.response.status == 403 || error.response.status == 401){
                store.commit('auth/logout');
                router.push("/login");

            }
            return Promise.reject(error.response.data);
		}
		return Promise.reject(error.message);
	}
);

/* axios.interceptors.response.use(
	(response) => {
		const now = Date.now();
		const request = response.config;
		console.info(
			`Api Call ${request.url} took ${now - request.config.start}ms`
		);

		if (request.config.requestToastId) {
			Vue.$toast.dismiss(request.config.requestToastId);
		}

		if (request.config.showToast && request.config.responseToast) {
			Vue.$toast(request.config.responseToast.title);
		}
		return response;
	},
	(error) => Promise.reject(error)
); */

export default axios;
