<template>
    <div>
        <v-card outlined class="theme">
            <v-row>
                <v-col align-self="center" class="ml-5">
                    <v-btn v-if="checkAllowList && haveRole('filters')" elevation="0" @click="$parent.sheet = true">Filtrar</v-btn>
                </v-col>
                <v-col class="text-center" cols="auto">
                    <h1>{{ title }}</h1>
                </v-col>
                <v-col align-self="center" class="text-right mr-5">
                    <router-link v-if="pathCreate && haveRole(roleCreate)" :to="{name: pathCreate}">
                        <v-btn color="primary" elevation="0">Nuevo</v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Title",
    props: {
        title: {
            type: String,
            default: "Sin titulo",
        },
        pathCreate: {
            type: String
        },
        roleCreate: {
            type: String
        }
    },
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        checkAllowList(){
            var allowList = ['Productos', 'Proveedores', 'Almacen', 'Salidas']
            return allowList.find((listname)=>{
                return listname == this.$parent.$options.name
            });
        }
    }
};
</script>

<style scoped>
.theme {
    background: var(--ecr-verde) !important;
}
.theme h1 {
    color: white;
}
</style>