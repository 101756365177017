<template>
    <div style="">
        <v-row justify="space-between" class="ma-0">
            <router-link
                v-for="item in list"
                :key="item.id"
                :to="{ name: editPath, params: { id: item.id } }"
                class="pt-5"
            >
                <v-card outlined elevation="0" rounded="lg" width="300">
                    <v-card-title
                        class="fondo-grid-item-title white--text text-uppercase"
                        style="word-break: break-word"
                    >
                        {{ item[fieldTitle] }}
                        <v-spacer></v-spacer>
                        <v-sheet v-if="item.deleted" color="#CC0000" rounded height="40" width="40" class="text-center">
                            <v-icon large color="#ffffff"
                                >mdi-delete-outline</v-icon
                            >
                        </v-sheet>
                    </v-card-title>
                    <div v-if="gridType == 'productos'">
                        <grid-item-info-component
                            :mix="item.isMixed"
                            :ground="item.ground"
                            :favourite="item.favourite"
                        ></grid-item-info-component>
                    </div>

                    <v-divider v-if="gridType == 'productos'"></v-divider>

                    <v-list-item v-if="gridType == 'productos'">
                        <v-list-item-icon v-if="item.salePrice">
                            <v-icon x-large>mdi-currency-eur</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                            v-if="item.salePrice"
                            class="headline"
                            >{{ item.salePrice }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-else class="headline"
                            >Precio no disponible</v-list-item-subtitle
                        >
                    </v-list-item>

                    <v-list-item v-if="item.phone">
                        <v-list-item-icon>
                            <v-icon x-large>mdi-phone-classic</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="headline">{{
                            item.phone
                        }}</v-list-item-subtitle>
                    </v-list-item>

                    <v-divider v-if="item.phone"></v-divider>

                    <v-list-item v-if="item.email">
                        <v-list-item-icon>
                            <v-icon x-large>mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="headline">{{
                            item.email
                        }}</v-list-item-subtitle>
                    </v-list-item>
                </v-card>
            </router-link>
            <!-- </v-layout> -->
        </v-row>
    </div>
</template>

<script>
import GridItemInfoComponent from "@/components/GridItemInfoComponent.vue";

export default {
    components: {
        GridItemInfoComponent,
    },
    props: {
        gridType: {
            type: String,
        },
        fieldTitle: {
            type: String,
            default: "name",
        },
        editPath: {
            type: String,
            required: true,
        },
        list: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            item: {},
        };
    },
};
</script>

<style>
.fondo-grid-item-title {
    background: var(--ecr-verde);
}
</style>