var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-stepper',{attrs:{"elevation":"0"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.product != null,"step":"1"}},[(_vm.product)?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.product = null;
                        _vm.step = 1;}}},[_vm._v("Cambiar producto")]):_c('span',[_vm._v("Seleccionar producto")])],1),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" Crear salida ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[(!_vm.product)?_c('v-row',[_c('custom-search-component',{attrs:{"items":_vm.products,"itemText":"name"},on:{"change":function($event){return _vm.selectProduct($event)}}})],1):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"2"}},[(_vm.product)?_c('title-component',{attrs:{"title":_vm.productName}}):_vm._e(),_c('v-row',{staticClass:"mt-5 mb-10"},[(_vm.showInputsTable)?_c('v-card',{attrs:{"outlined":"","width":"100%"}},[_c('v-card-title',[_c('b',[_vm._v("EN USO")])]),_c('table-component',{attrs:{"headers":[
                                {
                                    text: 'Lote',
                                    align: 'start',
                                    value: 'lot',
                                },
                                {
                                    text: 'producto',
                                    value: 'product.name',
                                },
                                { text: 'Total', value: 'weight' },
                                {
                                    text: 'Restante',
                                    value: 'remaining',
                                },
                                {
                                    text: 'Precio venta',
                                    value: 'salePrice',
                                },
                                {
                                    text: 'Proveedor',
                                    value: 'provider.companyName',
                                },
                                {
                                    text: 'Estado',
                                    value: 'inputState.name',
                                },
                                { text: 'Fecha', value: 'createAt' },
                                { text: 'Acciones', value: 'actions' },
                            ],"items":_vm.inputs,"type":"salida","selectable":true,"selectedItems":_vm.inputSelected,"expanded":_vm.inputs,"show-expand":"","item-key":"id"},on:{"update:expanded":function($event){_vm.inputs=$event},"selectButtonItem":function($event){_vm.clearForm(), _vm.addGrindingItems($event)},"putToUse":_vm.putStateItemInUse,"finishInput":_vm.finishInput}})],1):_vm._e(),_c('v-col',[(!this.inputs.length && this.product)?_c('p',{staticClass:"text-body-1 font-weight-bold text-center"},[_vm._v(" No hay entradas disponibles del producto seleccionado ")]):_vm._e()])],1),_c('v-row',{staticClass:"mt-5 mb-10"},[(_vm.showInputsTable)?_c('v-card',{attrs:{"outlined":"","width":"100%"}},[_c('v-card-title',[_c('b',[_vm._v("EN ESPERA")])]),_c('table-component',{attrs:{"headers":[
                                {
                                    text: 'Lote',
                                    align: 'start',
                                    value: 'lot',
                                },
                                {
                                    text: 'producto',
                                    value: 'product.name',
                                },
                                { text: 'Total', value: 'weight' },
                                {
                                    text: 'Restante',
                                    value: 'remaining',
                                },
                                {
                                    text: 'Precio venta',
                                    value: 'salePrice',
                                },
                                {
                                    text: 'Proveedor',
                                    value: 'provider.companyName',
                                },
                                {
                                    text: 'Estado',
                                    value: 'inputState.name',
                                },
                                { text: 'Fecha', value: 'createAt' },
                                { text: 'Acciones', value: 'actions' },
                            ],"items":_vm.inputsWaiting,"type":"salida","selectable":true,"selectedItems":_vm.inputSelected,"item-key":"id"},on:{"selectButtonItem":function($event){_vm.clearForm(), _vm.addGrindingItems($event)},"putToUse":_vm.putStateItemInUse,"finishInput":_vm.finishInput}})],1):_vm._e(),_c('v-col',[(!this.inputs.length && this.product)?_c('p',{staticClass:"text-body-1 font-weight-bold text-center"},[_vm._v(" No hay entradas en espera del producto seleccionado ")]):_vm._e()])],1),_c('modal-component',{attrs:{"active":_vm.modal,"max-width":"600px"},on:{"close":function($event){(_vm.modal = false), (_vm.inputSelected = [])}}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-title',{staticClass:"text-center"},[_vm._v(" Salida ")]),_c('v-card-text',[_c('v-row',{staticClass:"ma-0"},[_vm._l((_vm.clients),function(item){return _c('v-card',{key:item.id,staticClass:"ma-1",staticStyle:{"height":"fit-content"},attrs:{"outlined":"","elevation":"0","rounded":"lg"},on:{"click":function($event){return _vm.selectClient(item)}}},[_c('v-card-text',{staticClass:"white--text text-uppercase",class:(_vm.form.client == item.id)
                                                    ? `back-selected output-user-gris-item-${item.id}`
                                                    : `output-user-gris-item-${item.id}`,staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(item["name"])+" ")])],1)}),_c('v-col',[(!_vm.clients.length)?_c('p',{staticClass:"text-body-1 font-weight-bold text-center"},[_vm._v(" No hay datos disponibles ")]):_vm._e()])],2),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"off","outlined":"","label":"Nº Lote*","disabled":""},model:{value:(_vm.lot),callback:function ($$v) {_vm.lot=$$v},expression:"lot"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"off","outlined":"","label":"Precio venta","type":"number","disabled":"","error-messages":_vm.priceErrors},model:{value:(_vm.$v.form.price.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.price, "$model", $$v)},expression:"$v.form.price.$model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuFecha",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                    on,
                                                    attrs,
                                                }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Fecha*","persistent-hint":"","prepend-icon":"mdi-calendar","error-messages":_vm.createAtErrors},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFecha),callback:function ($$v) {_vm.menuFecha=$$v},expression:"menuFecha"}},[_c('v-date-picker',{on:{"change":_vm.convertDate,"input":function($event){_vm.menuFecha = false}},model:{value:(
                                                    _vm.$v.form.createAt.$model
                                                ),callback:function ($$v) {_vm.$set(_vm.$v.form.createAt, "$model", $$v)},expression:"\n                                                    $v.form.createAt.$model\n                                                "}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"off","outlined":"","type":"number","label":"Cantidad (gramos)*","error-messages":_vm.weightErrors},model:{value:(_vm.$v.form.weight.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.weight, "$model", $$v)},expression:"$v.form.weight.$model"}})],1)],1)],1),(_vm.haveRole('outputedit'))?_c('v-card-actions',[(
                                        _vm.$v.form.id.$model && !_vm.form.deleted
                                    )?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"error","elevation":"0","loading":_vm.loading},on:{"click":_vm.remove}},[_vm._v("Eliminar")]):_vm._e(),(_vm.$v.form.id.$model && _vm.form.deleted)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"info","elevation":"0","loading":_vm.loading},on:{"click":_vm.restore}},[_vm._v("Recuperar")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","elevation":"0","type":"submit","loading":_vm.loading,"disabled":_vm.$v.$invalid}},[_vm._v("Guardar")])],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }