<template>
    <v-bottom-sheet v-model="$parent.sheet">
        <v-sheet class="text-center pt-5" :fullscreen="true">
            <h2>Filtros</h2>
            <!-- <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn> -->

            <v-form
                @submit.prevent="
                    $parent.search();
                    $parent.sheet = false;
                "
            >
                <v-row>
                    <v-col>
                        <v-text-field
                            autocomplete="off"
                            outlined
                            v-model="$parent.params.search"
                            label="Buscador"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="products">
                    <v-col>
                        <v-checkbox
                            v-model="$parent.params.favourite"
                            label="Favorito"
                            indeterminate
                        ></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="$parent.params.ground"
                            label="Molido"
                            indeterminate
                        ></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="$parent.params.isMixed"
                            label="Mezclado"
                            indeterminate
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="inputs">
                    <v-col>
                        <custom-autocomplete-component
                                v-model="$parent.params.state"
                                outlined
                                multiple
                                query="/input-states"
                                label="Estados"
                                item-text="name"
                                item-value="id"
                            ></custom-autocomplete-component>
                    </v-col>
                </v-row>
                <v-row v-if="outputs">
                    <v-col>
                        <custom-autocomplete-component
                                v-model="$parent.params.client"
                                outlined
                                multiple
                                query="/clients"
                                label="Clientes"
                                item-text="name"
                                item-value="id"
                            ></custom-autocomplete-component>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn elevation="0" x-large color="info" type="submit">
                            Aplicar
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn elevation="0" x-large color="warning" @click="$parent.clearFilters(); $parent.sheet = false;">
                            Borrar filtros
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import CustomAutocompleteComponent from "@/components/CustomAutocompleteComponent";

export default {
    name: "FilterComponent",
    components: {
        CustomAutocompleteComponent
    },
    computed: {
        products() {
            return this.$parent.$options.name == "Productos";
        },
        inputs() {
            return this.$parent.$options.name == "Almacen";
        },
        outputs() {
            return this.$parent.$options.name == "Salidas";
        },
    },
};
</script>

<style>
</style>