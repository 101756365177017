<template>
    <modal-component
        :active="active"
        max-width="1400px"
        @close="$emit('close')"
    >
        <v-card class="mt-5">
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                    >Entradas disponibles
                    <!-- {{ input.product && input.product.name }} -->
                    </v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn small fab elevation="0" @click="$emit('close')">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <!-- <p>{{ input }}</p> -->
                <table-component
                    :headers="[
                        {
                            text: 'Lote',
                            align: 'start',
                            sortable: false,
                            value: 'lot',
                        },
                        { text: 'producto', value: 'product.name' },
                        { text: 'Total', value: 'weight' },
                        { text: 'Restante', value: 'remaining' },
                        { text: '€/Kg.', value: 'price' },
                        {
                            text: 'Proveedor',
                            value: 'provider.companyName',
                        },
                        {
                            text: 'Estado',
                            value: 'inputState.name',
                        },
                        { text: 'Fecha', value: 'createAt' },
                        { text: 'Acciones', value: 'actions' },
                    ]"
                    :items="inputs"
                    :selectable="true"
                    :selectedItems="input.composition"
                    @selectButtonItem="addInputMovement"
                    @putToUse="putStateItemInUse"
                >
                </table-component>
            </v-card-text>
        </v-card>
    </modal-component>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
export default {
    components: {
        ModalComponent,
        TableComponent,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        entrada: {
            type: Object,
            default: null,
        },
        product: {
            type: Number
        }
    },
    data() {
        return {
            inputs: [],
            input: {
                product: null,
                composition: [],
            },
        };
    },
    watch: {
        active() {
            // console.log("active", this.active);
            // console.log("product", this.product);
            if(this.product){
                this.getInputs();
            }
        },
        product(){
            this.input = this.entrada;
            // console.log("input", this.input, this.product);
            /* if(this.product){
                this.getInputs();
            } */

        },
        /* entrada: {
            handler() {
                console.log("watch");
            },
            deep: true,
        }, */
    },
    methods: {
        async getInputs() {
            //Obtenemos todas las entradas en uso filtradas por producto
            const { data } = await this.$http.get(
                `/inputs/getInputsCompositionsInUseFilterByProduct/${this.product}`
            );
            // console.log("getInputs", data);
            this.inputs = data;
        },
        addInputMovement(input) {
            // console.log("addInputMovement", input);
            if (!this.input.composition.some((gm) => gm.input.id == input.id)) {
                var grinding = {
                    weight: null,
                    productTransformation:
                        this.input.productTransformation,
                    input: input,
                    composition: this.input.id,
                };

                this.input.composition.push(grinding);
            }
        },
        async putStateItemInUse(input) {
            const { data } = await this.$http.get(
                `/inputs/putInputInUse/${input.id}`
            );
            input.inputState = data;
            // console.log("putStateItemInUse", input);
        },
    },
};
</script>

<style>
</style>