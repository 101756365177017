import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueAxios from "vue-axios";
import axios from "./plugins/axios";
import Vuelidate from "vuelidate";
import NavComponent from "./components/NavComponent";
import infiniteScroll from "vue-infinite-scroll";
import InfiniteLoading from "vue-infinite-loading";
import "@/scss/app.scss"

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(Toast, { timeout: 1500 });
Vue.use(Vuelidate);
Vue.use(infiniteScroll);
Vue.use(InfiniteLoading, {
	props: {
		spinner: "waveDots",
		distance: 1,
	},
	system: {
		throttleLimit: 50,
		/* other settings need to configure */
	},
});

store.$axios = axios;

new Vue({
	components: {
		NavComponent,
	},
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
