<template>
    <v-card outlined>
        <div class="header-card-user"></div>
        <div>
            <v-row justify="center">
                <v-avatar size="150" class="user-image">
                    <img
                        :src="
                            require('../assets/web/users/' + user.id + '.jpg')
                        "
                    />
                </v-avatar>
            </v-row>
        </div>
        <div>
            <v-row justify="center">
                <p class="text-h5 text-uppercase">
                    {{ user.name }}
                </p>
            </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
            <v-row justify="center" v-if="haveRole('useredit')">
                <router-link
                    :to="{ name: 'editarUsuario', params: { id: user.id } }"
                >
                    <v-btn elevation="0" color="warning">Editar</v-btn>
                </router-link>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
    name: "UserCard",
    props: {
        user: {
            type: Object,
        },
    },
    computed: {
        ...mapState("auth", ["roles"]),
        ...mapGetters("auth", ["haveRole"])
    }
};
</script>

<style>
.header-card-user {
    height: 100px;
    background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#2195f3),
        to(var(--ecr-verde))
    );
    background: linear-gradient(to top, #2195f3 0, #2bbbad 100%);
    margin-bottom: -75px;
}

.user-image {
    border: 5px solid white;
}
</style>