var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('modal-component',{attrs:{"active":_vm.fullInput != null,"max-width":"1400px"},on:{"close":function($event){_vm.fullInput = null}}},[(_vm.fullInput)?_c('v-card',{attrs:{"color":"grey lighten-4"}},[_c('v-card-title',{staticClass:"white--text bg-ecr-verde"},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Lote: "+_vm._s(_vm.fullInput.lot)+" ")]),_c('v-col',{staticClass:"ml-5",attrs:{"cols":"auto"}},[_vm._v(" Total: "+_vm._s(_vm.fullInput.weight)+" g ")]),_c('v-col',{staticClass:"ml-5",attrs:{"cols":"auto"}},[_vm._v(" Restante: "+_vm._s(_vm.fullInput.remaining)+" g ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","elevation":"0","color":"default"},on:{"click":function($event){_vm.fullInput = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"white--text bg-primary"},[_vm._v("Entrada")]),_c('v-card-text',[_c('table-component',{attrs:{"headers":[
                                { text: 'Producto', value: 'product.name' },
                                { text: 'Total', value: 'weight' },
                                { text: 'Restante', value: 'remaining' },
                                {
                                    text: 'Proveedor',
                                    value: 'provider.companyName',
                                },
                                {
                                    text: 'Estado',
                                    value: 'inputState.name',
                                },
                                { text: 'Fecha', value: 'createAt' },
                            ],"items":[_vm.fullInput]}})],1)],1),(_vm.fullInput && _vm.fullInput.composition.length)?_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"white--text bg-primary"},[_vm._v(" Composición ")]),_c('v-card-text',[_c('table-component',{attrs:{"headers":[
                                { text: 'Producto', value: 'input.product.name' },
                                { text: 'Cantidad', value: 'weight' },
                                {
                                    text: 'Cliente',
                                    value: 'client.name',
                                },
                                { text: 'Fecha', value: 'createAt' },
                            ],"items":_vm.fullInput.composition}})],1)],1):_vm._e(),(_vm.fullInput && _vm.fullInput.outputs.length)?_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"white--text bg-primary"},[_vm._v("Salidas")]),_c('v-card-text',[_c('table-component',{attrs:{"headers":[
                                { text: 'Cantidad', value: 'weight' },
                                {
                                    text: 'Cliente',
                                    value: 'client.name',
                                },
                                { text: 'Fecha', value: 'createAt' },
                            ],"items":_vm.fullInput.outputs}})],1)],1):_vm._e()],1)],1):_vm._e()],1),_c('title-component',{attrs:{"title":"Almacén","pathCreate":"nuevaEntrada","roleCreate":"inputedit"}}),_c('filter-component',{on:{"search":_vm.search}}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('table-component',{attrs:{"headers":[
                {
                    text: 'Info.',
                    sortable: false,
                    value: 'info',
                },
                {
                    text: 'Lote',
                    align: 'start',
                    sortable: false,
                    value: 'lot',
                },
                { text: 'Producto', value: 'product.name' },
                { text: 'Total', value: 'weight' },
                { text: 'Restante', value: 'remaining' },
                { text: 'Precio compra', value: 'price' },
                { text: 'Precio venta', value: 'salePrice' },
                { text: 'Proveedor', value: 'provider.companyName' },
                { text: 'Estado', value: 'inputState.name' },
                { text: 'Fecha', value: 'createAt' },
                { text: 'Acciones', value: 'actions' },
            ],"items":_vm.items,"editPathName":"editarEntrada","roleEdit":"inputedit"},on:{"info":_vm.getFullInput}})],1)],1),_c('infinite-loading',{ref:"infiniteLoading",staticClass:"mt-5",on:{"infinite":_vm.getInfiniteItems}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("No hay más elementos")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }