<template>
    <v-container fluid>
        <title-component title="Nueva entrada en almacén"></title-component>
        <form-entradas></form-entradas>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormEntradas from "@/views/almacen/Form.vue";

export default {
    components: {
        TitleComponent,
        FormEntradas,
    },
};
</script>

<style>
</style>