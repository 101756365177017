export function haveRole(state) {
    return function(role) {
        if (state.roles.admin) {
            return state.roles.admin;
        }
        return state.roles[role];
    };
}

/* export function haveRole(state) {
    return function(role) {
        if (state.isSuperAdmin) {
            return state.isSuperAdmin;
        }
        return state.roles[role];
    };
} */
