<template>
    <div>
        <v-navigation-drawer
            :mini-variant="mini"
            mini-variant-width="130"
            permanent
            app
            dark
            color="#3b3b42"
        >
            <v-list dense>
                <router-link
                    v-show="haveRole(item.rolRequired)"
                    v-for="item in menu"
                    :key="item.title"
                    :to="{ name: item.linkName }"
                >
                    <v-list-item link>
                        <v-list-item-action class="ml-0">
                            <v-row justify="center" class="ma-0">
                                <v-col cols="12" class="pa-0">
                                    <v-row justify="center" class="ma-0">
                                        <v-icon class="icon-active" size="50">{{
                                            item.icon
                                        }}</v-icon>
                                    </v-row>
                                </v-col>
                                <v-col class="pa-0">
                                    <v-row justify="center" class="ma-0">
                                        <label class="title-active" :for="item.title">{{
                                            item.title
                                        }}</label>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </router-link>
            </v-list>

            <v-list dense>
                <v-list-item link @click="_logout">
                        <v-list-item-action class="ml-0">
                            <v-row justify="center" class="ma-0">
                                <v-col cols="12" class="pa-0">
                                    <v-row justify="center" class="ma-0">
                                        <v-icon class="icon-active" size="50">mdi-logout</v-icon>
                                    </v-row>
                                </v-col>
                                <v-col class="pa-0">
                                    <v-row justify="center" class="ma-0">
                                        <label class="title-active">Salir</label>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
    data() {
        return {
            menu: [
                {
                    linkName: "almacen",
                    title: "Entradas",
                    icon: "mdi-arrow-left-bold-box-outline",
                    rolRequired: "inputlist"
                },
                {
                    linkName: "nuevaSalida",
                    title: "Salidas",
                    icon: "mdi-arrow-right-bold-box-outline",
                    /* permission: "show clients", */
                    rolRequired: "outputedit"
                },
                {
                    linkName: "proveedores",
                    title: "Proveedores",
                    icon: "mdi-account-group-outline",
                    rolRequired: "providerlist"
                },
                {
                    linkName: "productos",
                    title: "Catálogo",
                    icon: "mdi-animation-outline",
                    rolRequired: "productlist"
                },
                {
                    linkName: "usuarios",
                    title: "Usuarios",
                    icon: "mdi-account-box-multiple",
                    rolRequired: "userlist"
                },
                {
                    linkName: "documentos",
                    title: "Documentos",
                    icon: "mdi-file-document",
                    rolRequired: "filelist"
                }
            ],
            mini: true,
        };
    },
    mounted() {},
    computed: {
        ...mapState("auth", ["user", "roles"]),
        ...mapState(["loading"]),
        ...mapGetters("auth", ["haveRole"])
    },
    methods: {
        ...mapMutations('auth', ['logout']),
        _logout () {
          this.logout()
          this.$router.push('/login')
        }
        /* async logout() {
            await this.$http.post("/logout");
            location.reload();
        }, */
    },
};
</script>

<style scoped>
/* a {
    text-decoration: none;
} */
.is-active > div {
    background: var(--background-menu);
}

.is-active .icon-active {
    color: var(--primary);
    transition: color 0.5s;
}

.is-active .title-active {
    font-size: 1.1rem;
    color: var(--primary);
    transition: font-size 0.5s, color 0.5s;
}

.v-navigation-drawer--mini-variant .v-list-item>:first-child{
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>
