<template>
    <v-container fluid>
        <title-component title="Editar producto"></title-component>
        <form-productos :item="producto"></form-productos>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormProductos from "./Form"

export default {
    components: {
        TitleComponent,
        FormProductos
    },
    data() {
        return {
            producto: {},
        };
    },
    mounted() {
        this.getProduct();
    },
    methods: {
        async getProduct() {
            try {
                const { data } = await this.$http.get(
                    `/products/${this.$route.params.id}`
                );
                var tmpPC = []
                data.productComposition.forEach(pc => {
                    tmpPC.push(pc.id);
                });
                data.productComposition = tmpPC
                this.producto = data;
                // console.log("producto", data);
            } catch (error) {}
        },
        /* async save(form) {
            try {
                await this.$http.put(`/products/${this.$route.params.id}`, form);
                this.$toast.success("Guardado correctamente");
            } catch (error) {}
            finally {
                this.setLoading(false);
            }
        }, */
    },
};
</script>

<style>
</style>