<template>
    <v-app>
        <loading
            :active.sync="loading"
            :width="125"
            :height="125"
            color="#00A"
            loader="spinner"
            background-color="#ffffff00"
            :opacity="1"
        ></loading>
        <nav-component v-if="isLogged"></nav-component>

        <v-main class="bg-color">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import NavComponent from "./components/NavComponent";
import { mapState } from "vuex";

export default {
    name: "App",

    components: {
        Loading,
        NavComponent,
    },

    data: () => ({
        //
    }),
    computed: {
        ...mapState("auth", ["isLogged"]),
        ...mapState(["loading"]),
    },
};
</script>

<style lang="scss">
.bg-color {
    background-color: var(--background-menu);
}

.bg-primary {
    background: var(--primary) !important;
}
.bg-ecr-verde {
    background: var(--ecr-verde) !important;
}
</style>
