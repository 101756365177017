<template>
    <v-container fluid>
        <title-component title="Nuevo proveedor"></title-component>
        <form-proveedores></form-proveedores>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormProveedores from "@/views/proveedores/Form.vue";

export default {
    components: {
        TitleComponent,
        FormProveedores,
    },
    methods: {
    }
};
</script>

<style>
</style>