<template>
    <v-col cols="12" md="12">
        <v-card outlined>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Datos del usuario</v-toolbar-title>
            </v-toolbar>
            <v-form @submit.prevent="submit">
                <v-card-text>
                    <div>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.firstname.$model"
                                    :error-messages="firstnameErrors"
                                    label="Nombre*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.surname.$model"
                                    label="Apellidos"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-autocomplete
                                    v-show="haveRole('admin')"
                                    v-model="form.roles"
                                    :items="roles"
                                    chips
                                    deletable-chips
                                    outlined
                                    color="blue-grey lighten-2"
                                    label="Roles"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-4"
                        color="primary"
                        type="submit"
                        elevation="0"
                        :loading="loading"
                        >Guardar</v-btn
                    >
                    <!-- <v-btn @click="showDialog" color="default">Limpiar</v-btn> -->
                </v-card-actions>
            </v-form>
        </v-card>
    </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { mapMutations, mapState, mapGetters } from "vuex";
export default {
    mixins: [validationMixin],
    props: {
        item: Object,
    },
    validations: {
        form: {
            firstname: { required },
            surname: {},
        },
    },
    data() {
        return {
            form: {
                firstname: "",
                surname: "",
            },
            roles: [],
        };
    },
    watch: {
        item() {
            this.form = {
                firstname: this.item.firstname,
                surname: this.item.surname,
                roles: this.item.roles
            }
        },
    },
    mounted() {
        this.getRoles();
    },
    computed: {
        ...mapState(["loading"]),
        ...mapGetters("auth", ["haveRole"]),
        firstnameErrors() {
            const errors = [];
            if (!this.$v.form.firstname.$dirty) return errors;
            !this.$v.form.firstname.required && errors.push("Obligatorio");
            return errors;
        },
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getRoles() {
            try {
                const { data } = await this.$http.get("/roles");
                this.roles = data;
            } catch (error) {}
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.setLoading(true);
                this.$emit("doSave", this.form);
            }
        },
    },
};
</script>

<style>
</style>