<template>
    <v-row class="pa-4" justify="space-around">
        <v-avatar size="40" rounded :color="mix ? 'fondo-mix' : 'grey'">
            <v-icon x-large :color="mix ? 'yellow lighten-3' : 'black'"
                >mdi-filter</v-icon
            >
        </v-avatar>
        <v-avatar size="40" rounded :color="ground ? 'fondo-ground' : 'grey'">
            <v-icon x-large :color="ground ? 'yellow lighten-3' : 'black'">mdi-pot-mix</v-icon>
        </v-avatar>
        <v-avatar size="40" rounded :color="favourite ? 'fondo-favourite' : 'grey'">
            <v-icon x-large :color="favourite ? 'yellow lighten-3' : 'black'">mdi-star</v-icon>
        </v-avatar>
        <!-- <v-col cols="4">
            <div v-if="ground">
                <v-avatar size="40" rounded color="grey lighten-1">
                    <v-icon large color="yellow">mdi-pot-mix</v-icon>
                </v-avatar>
            </div>
        </v-col>
        <v-col cols="4">
            <div v-if="favourite">
                <v-avatar size="40" rounded color="grey lighten-1">
                    <v-icon large color="yellow">mdi-star</v-icon>
                </v-avatar>
            </div>
        </v-col> -->
    </v-row>
</template>

<script>
export default {
    props: {
        mix: {
            type: Boolean,
        },
        ground: {
            type: Boolean,
        },
        favourite: {
            type: Boolean,
        },
    },
};
</script>

<style>
.fondo-mix {
    background: var(--ecr-gii);
}
.fondo-ground {
    background: var(--ecr-gii);
}
.fondo-favourite {
    background: var(--ecr-gii);
}
</style>