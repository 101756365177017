<template>
    <div>
        <v-stepper v-model="step" elevation="0">
            <v-stepper-header>
                <v-stepper-step :complete="product != null" step="1">
                    <v-btn
                        v-if="product"
                        color="primary"
                        elevation="0"
                        @click="
                            product = null;
                            step = 1;
                        "
                        >Cambiar producto</v-btn
                    >
                    <span v-else>Seleccionar producto</span>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2"> Crear salida </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row v-if="!product">
                        <!-- Listado con los productos -->
                        <custom-search-component
                            :items="products"
                            itemText="name"
                            @change="selectProduct($event)"
                        ></custom-search-component>
                    </v-row>
                    <!-- Fin listado productos -->
                </v-stepper-content>

                <v-stepper-content step="2">
                    <title-component
                        v-if="product"
                        :title="productName"
                    ></title-component>

                    <!-- Entradas en curso del producto seleccionado -->
                    <v-row class="mt-5 mb-10">
                        <v-card v-if="showInputsTable" outlined width="100%">
                            <v-card-title>
                                <b>EN USO</b>
                            </v-card-title>
                            <table-component
                                :headers="[
                                    {
                                        text: 'Lote',
                                        align: 'start',
                                        value: 'lot',
                                    },
                                    {
                                        text: 'producto',
                                        value: 'product.name',
                                    },
                                    { text: 'Total', value: 'weight' },
                                    {
                                        text: 'Restante',
                                        value: 'remaining',
                                    },
                                    {
                                        text: 'Precio venta',
                                        value: 'salePrice',
                                    },
                                    {
                                        text: 'Proveedor',
                                        value: 'provider.companyName',
                                    },
                                    {
                                        text: 'Estado',
                                        value: 'inputState.name',
                                    },
                                    { text: 'Fecha', value: 'createAt' },
                                    { text: 'Acciones', value: 'actions' },
                                ]"
                                :items="inputs"
                                type="salida"
                                :selectable="true"
                                :selectedItems="inputSelected"
                                :expanded.sync="inputs"
                                show-expand
                                item-key="id"
                                @selectButtonItem="clearForm(), addGrindingItems($event)"
                                @putToUse="putStateItemInUse"
                                @finishInput="finishInput"
                            >
                                <!-- @editOutput="editOutput($event)" -->
                            </table-component>
                            <!-- :selectedItems="form.outputMovements" -->
                        </v-card>
                        <v-col>
                            <p
                                v-if="!this.inputs.length && this.product"
                                class="text-body-1 font-weight-bold text-center"
                            >
                                No hay entradas disponibles del producto
                                seleccionado
                            </p>
                        </v-col>
                    </v-row>

                    <v-row class="mt-5 mb-10">
                        <v-card v-if="showInputsTable" outlined width="100%">
                            <v-card-title>
                                <b>EN ESPERA</b>
                            </v-card-title>
                            <table-component
                                :headers="[
                                    {
                                        text: 'Lote',
                                        align: 'start',
                                        value: 'lot',
                                    },
                                    {
                                        text: 'producto',
                                        value: 'product.name',
                                    },
                                    { text: 'Total', value: 'weight' },
                                    {
                                        text: 'Restante',
                                        value: 'remaining',
                                    },
                                    {
                                        text: 'Precio venta',
                                        value: 'salePrice',
                                    },
                                    {
                                        text: 'Proveedor',
                                        value: 'provider.companyName',
                                    },
                                    {
                                        text: 'Estado',
                                        value: 'inputState.name',
                                    },
                                    { text: 'Fecha', value: 'createAt' },
                                    { text: 'Acciones', value: 'actions' },
                                ]"
                                :items="inputsWaiting"
                                type="salida"
                                :selectable="true"
                                :selectedItems="inputSelected"
                                item-key="id"
                                @selectButtonItem="clearForm(), addGrindingItems($event)"
                                @putToUse="putStateItemInUse"
                                @finishInput="finishInput"
                            >
                            </table-component>
                            <!-- :selectedItems="form.outputMovements" -->
                        </v-card>
                        <v-col>
                            <p
                                v-if="!this.inputs.length && this.product"
                                class="text-body-1 font-weight-bold text-center"
                            >
                                No hay entradas en espera del producto
                                seleccionado
                            </p>
                        </v-col>
                    </v-row>

                    <modal-component
                        :active="modal"
                        max-width="600px"
                        @close="(modal = false), (inputSelected = [])"
                    >
                        <v-card outlined>
                            <v-form @submit.prevent="submit">
                                <v-card-title class="text-center">
                                    Salida <!-- {{form}} -->
                                </v-card-title>
                                <v-card-text>
                                    <v-row class="ma-0">
                                        <v-card
                                            class="ma-1"
                                            outlined
                                            elevation="0"
                                            rounded="lg"
                                            style="height: fit-content"
                                            v-for="item in clients"
                                            :key="item.id"
                                            @click="selectClient(item)"
                                        >
                                            <v-card-text
                                                class="
                                                    white--text
                                                    text-uppercase
                                                "
                                                :class="
                                                     
                                                    (form.client == item.id)
                                                        ? `back-selected output-user-gris-item-${item.id}`
                                                        : `output-user-gris-item-${item.id}`
                                                "
                                                style="word-break: break-word"
                                            >
                                                {{ item["name"] }}
                                            </v-card-text>
                                        </v-card>
                                        <v-col>
                                            <p
                                                v-if="!clients.length"
                                                class="
                                                    text-body-1
                                                    font-weight-bold
                                                    text-center
                                                "
                                            >
                                                No hay datos disponibles
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-5">
                                        <v-col cols="6">
                                            <v-text-field
                                                autocomplete="off"
                                                outlined
                                                v-model="lot"
                                                label="Nº Lote*"
                                                disabled
                                            ></v-text-field>
                                            <!-- :error-messages="lotErrors" -->
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                autocomplete="off"
                                                outlined
                                                v-model="$v.form.price.$model"
                                                label="Precio venta"
                                                type="number"
                                                disabled
                                                :error-messages="priceErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menuFecha"
                                                v-model="menuFecha"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="date"
                                                        outlined
                                                        label="Fecha*"
                                                        persistent-hint
                                                        prepend-icon="mdi-calendar"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :error-messages="
                                                            createAtErrors
                                                        "
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        $v.form.createAt.$model
                                                    "
                                                    @change="convertDate"
                                                    @input="menuFecha = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                autocomplete="off"
                                                outlined
                                                v-model="$v.form.weight.$model"
                                                type="number"
                                                label="Cantidad (gramos)*"
                                                :error-messages="weightErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions v-if="haveRole('outputedit')">
                                    <v-btn
                                        v-if="
                                            $v.form.id.$model && !form.deleted
                                        "
                                        class="ml-4"
                                        color="error"
                                        elevation="0"
                                        :loading="loading"
                                        @click="remove"
                                        >Eliminar</v-btn
                                    >
                                    <v-btn
                                        v-if="$v.form.id.$model && form.deleted"
                                        class="ml-4"
                                        color="info"
                                        elevation="0"
                                        :loading="loading"
                                        @click="restore"
                                        >Recuperar</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mr-4"
                                        color="primary"
                                        elevation="0"
                                        type="submit"
                                        :loading="loading"
                                        :disabled="$v.$invalid"
                                        >Guardar</v-btn
                                    >
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </modal-component>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxValue } from "vuelidate/lib/validators";
import moment from "moment";

import TitleComponent from "@/components/Title.vue";
import ModalComponent from "@/components/ModalComponent";
import CustomSearchComponent from "@/components/CustomSearchComponent.vue";
/* import CustomSelectComponent from "@/components/CustomSelectComponent"; */
import TableComponent from "@/components/TableComponent.vue";

import { validateInteger } from "../../validations";

import { mapMutations, mapGetters } from "vuex";
export default {
    name: "FormularioSalidas",
    mixins: [validationMixin],
    components: {
        TitleComponent,
        ModalComponent,
        CustomSearchComponent,
        /* CustomSelectComponent, */
        /* CustomAutocompleteComponent, */
        TableComponent,
    },
    props: {
        item: Object,
    },
    validations() {
        return {
            products: [],
            clients: [],
            form: {
                id: {},
                client: { required },
                price: {},
                /* lot: { required }, */
                weight: {
                    required,
                    unitsPattern(weight) {
                        return validateInteger(weight);
                    },
                    maxValue:
                        this.inputMaxLength != null
                            ? maxValue(this.inputMaxLength)
                            : "",
                },
                createAt: { required },
                input: { required },
            },
        };
    },
    data() {
        return {
            step: 1,
            expanded: [],
            modal: null,

            productName: null,
            product: "",
            products: [],
            clients: [],
            inputMaxLength: null,
            inputSelected: [],
            lot: "",
            form: {
                client: "",
                price: "",
                weight: "",
                createAt: new Date().toISOString().substr(0, 10),
                input: null,
                //outputMovements: [],
            },
            inputs: [],
            inputsWaiting: [],
            loading: false,
            menuFecha: false,
            date: moment(new Date()).format("DD/MM/YYYY"),
        };
    },
    watch: {
        /* item() {
            this.form = this.item;
            this.getInputs(this.form.product.id);
        }, */
        item() {
            this.step = 2;
            this.product = this.item.input.product;
            this.productName = this.product.name;
            this.lot = this.item.input.lot;
            // console.log("item", this.item);
            this.form = {
                id: this.item.id,
                client: this.item.client.id,
                price: this.item.price,
                weight: this.item.weight,
                createAt: this.item.createAt,
                deleted: this.item.deleted,
            };
            this.totalWeight = this.form.weight;
            this.date = moment(this.form.createAt).format("DD/MM/YYYY"),
            //this.getTransformationType(this.form.product.id);
            /* this.getInputs(this.form.product.id); */
            this.getInputs(this.product);
            this.addGrindingItems(this.item.input);
        },
    },
    mounted() {
        this.getProduct();
        this.getClients();
    },
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        showInputsTable() {
            return this.inputs.length && this.product;
        },
        productErrors() {
            const errors = [];
            if (!this.$v.form.product.$dirty) return errors;
            !this.$v.form.product.required && errors.push("Obligatorio");
            return errors;
        },
        priceErrors() {
            const errors = [];
            if (!this.$v.form.price.$dirty) return errors;
            //!this.$v.form.price.required && errors.push("Obligatorio");
            return errors;
        },
        createAtErrors() {
            const errors = [];
            if (!this.$v.form.createAt.$dirty) return errors;
            !this.$v.form.createAt.required && errors.push("Obligatorio");
            return errors;
        },
        clientErrors() {
            const errors = [];
            if (!this.$v.form.client.$dirty) return errors;
            !this.$v.form.client.required && errors.push("Obligatorio");
            return errors;
        },
        /* lotErrors() {
            const errors = [];
            if (!this.$v.form.lot.$dirty) return errors;
            !this.$v.form.lot.required && errors.push("Obligatorio");
            return errors;
        }, */
        weightErrors() {
            const errors = [];
            if (!this.$v.form.weight.$dirty) return errors;
            !this.$v.form.weight.required && errors.push("Obligatorio");
            !this.$v.form.weight.unitsPattern &&
                errors.push("Debe introducir un número entero válido");
            !this.$v.form.weight.maxValue &&
                errors.push(`Restante: ${this.inputMaxLength}g`);
            return errors;
        },
    },
    methods: {
        ...mapMutations(["setLoading"]),
        addLetter(letter) {
            this.searchText = this.searchText.concat(letter);
        },
        removeLetter() {
            this.searchText = this.searchText.slice(0, -1);
        },
        selectProduct(item) {
            this.productName = item.name;
            this.product = item;
            //this.changeProduct(this.product);
            //no se si comentar
            /* const { data } = await this.$http.get(
                `/inputs/getlot/${idProduct}`
            );
            this.form.lot = data; */
            this.getPrice(this.product.id);
            this.inputs = [];
            this.getInputs(this.product);
            this.step++;
        },
        selectClient(item) {
            this.form.client = item.id;
            // console.log("client", item);
        },
        convertDate() {
            this.date = moment(this.form.createAt).format("DD/MM/YYYY");
        },
        async getProduct() {
            //Obtenemos los productos
            var params = {
                actualPage: 1,
                take: 99999,
                favourite: null,
                ground: null,
                isMixed: null,
            }
            const { data } = await this.$http.get(`/products`, {params});
            this.products = data;
        },
        async getClients() {
            //Obtenemos los clientes
            const { data } = await this.$http.get(`/clients/getAllWithoutAlmacen`);
            this.clients = data;
        },
        async getPrice(idProduct) {
            const { data } = await this.$http.get(`/products/${idProduct}`);
            this.form.price = data.salePrice;
        },
        async getInputs() {
            //Obtenemos todas las entradas en uso filtradas por producto
            /* const { data } = await this.$http.get(
                `/inputs/getAllInUseFilterByProduct/${idProduct}`
            ); */
            var r1 = await this.$http.get(`/inputs/getAllInUseByProduct/${this.product.id}`);
            this.inputs = r1.data;
            var params = {
                actualPage: 1,
                take: 20,
                search: "",
                state: [1],
                product: this.product.id,
                inputOrder: false
            };
            var r2 = await this.$http.get(`/inputs`, {params});
            this.inputsWaiting = r2.data;
        },
        clearForm(){
            // console.log("clear", this.form);
            delete this.form.id;
            this.form = {
                client: "",
                price: "",
                weight: "",
                createAt: new Date().toISOString().substr(0, 10),
                input: null,
            }
            // console.log("clear2", this.form);
        },
        addGrindingItems(inputItem) {
            // console.log("addGrindingItems", inputItem);
            this.form.price = inputItem.salePrice;
            this.form.input = inputItem.id;
            //EN LA EDICION, VIENE VACIO
            // this.form.weight = "";
            this.lot = inputItem.lot;
            this.inputMaxLength = inputItem.remaining;
            var output = {
                weight: null,
                productsTransformation: 3,
                input: inputItem,
            };
            this.inputSelected = [];
            this.inputSelected.push(output);
            //this.form.outputMovements = [];
            //this.form.outputMovements.push(output);

            //Mostramos el modal con el formulario
            this.modal = true;
        },
        async putStateItemInUse(inputItem) {
            // const { data } = 
            try {
                await this.$http.get(`/inputs/putInputInUse/${inputItem.id}`);
                this.getInputs()
            } catch (error) {

            }
            // inputItem.inputState = data;
        },
        async finishInput(input){
            try {
                await this.$http.get(`/inputs/finish/${input.id}`);
                this.$toast.success("Finalizada");
                this.getInputs()
                //this.$emit("refreshOutput");
            } catch (error) {}
        },
        /* editOutput(item){
            console.log("eo", item);
            this.form = {
                id: item.id,
                client: item.client.id,
                price: item.price,
                weight: item.weight,
                createAt: item.createAt,
            };
            this.modal = true
        }, */
        /* async selectItem(item) {
            this.form.input = item.id;
            this.form.lot = item.lot;
            this.inputMaxLength = item.remaining;
        }, */
        async submit() {
            // console.log(this.$v.form);
            this.$v.$touch();
            if (!this.$v.$invalid) {
                //this.setLoading(true);
                try {
                    if (!this.form.id) {
                        const { data } = await this.$http.post(
                            `/outputs`,
                            this.form
                        );
                        // console.log("outputs", data);
                        this.$router.push({
                            name: "editarSalida",
                            params: { id: data.id },
                        });
                            this.$emit("refreshOutput");
                    } else {
                        const { data } = await this.$http.put(
                            `/outputs/${this.$route.params.id}`,
                            this.form
                        );
                        console.log(data);
                        //this.form = data;
                        this.$emit("refreshOutput");
                    }
                    this.$toast.success("Guardado correctamente");
                } catch (error) {
                } finally {
                    this.inputSelected = [];
                    this.modal = false;
                    // this.$emit("refreshOutput");
                    //this.setLoading(false);
                }
            }
        },
        async remove() {
            // console.log("remove", this.form);
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.delete("/outputs/" + this.form.id);
                    this.$router.push({ name: "salidas" });
                    this.$toast.success("Eliminado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
        async restore() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.get("/outputs/restore/" + this.form.id);
                    this.form.deleted = false;
                    this.$toast.success("Recuperado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
    },
};
</script>

<style lang="scss">
.back-selected {
    background: rgb(22, 179, 1) !important;
    border: 2px solid green;
}

.output-user-gris-item-1 {
    background: var(--ecr-user-verde);
}
.output-user-gris-item-2 {
    background: var(--ecr-user-lila);
}
.output-user-gris-item-3 {
    background: var(--ecr-user-naranja);
}
.output-user-gris-item-4 {
    background: var(--ecr-user-azul);
}
.output-user-gris-item-5 {
    background: var(--ecr-user-gris);
}
</style>