<template>
    <v-container fluid>
        <title-component
            title="Proveedores"
            pathCreate="nuevoProveedor"
            roleCreate="provideredit"
        ></title-component>

        <filter-component @search="search"></filter-component>

        <grid-component
            :list="listProviders"
            gridType="proveedores"
            fieldTitle="companyName"
            editPath="editarProveedor"
        ></grid-component>
        <infinite-loading
            ref="infiniteLoading"
            @infinite="getInfiniteProviders"
            class="mt-5"
        >
            <div slot="no-more">No hay más elementos</div>
        </infinite-loading>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import GridComponent from "@/components/GridComponent.vue";

import { mapMutations } from "vuex";
export default {
    name: "Proveedores",
    components: {
        TitleComponent,
        FilterComponent,
        GridComponent,
    },
    data() {
        return {
            listProviders: [],
            params: {
                actualPage: 1,
                take: 20,
            },
            sheet: false,
        };
    },
    mounted() {},
    methods: {
        ...mapMutations(["setLoading"]),
        async getProviders() {
            try {
                const { data } = await this.$http.get("/providers", {
                    params: this.params,
                });
                this.listProviders = this.listProviders.concat(data);
                return data;
            } catch (error) {}
        },
        async getInfiniteProviders($state) {
            try {
                var items = await this.getProviders();
                $state.loaded();
                if (items.length == 0) $state.complete();
                else this.params.actualPage++;
            } catch (error) {}
        },
        async search() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.listProviders = [];
                this.params.actualPage = 1;
                // await this.getProviders();
                // this.params.actualPage++;
            } catch (error) {}
        },
        async clearFilters() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.listProviders = [];
                this.params = {
                    actualPage: 1,
                    take: 20,
                };
            } catch (error) {}
        },
    },
};
</script>

<style>
