<template>
    <v-col cols="12" md="12">
        <v-card outlined>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Datos del producto</v-toolbar-title>
            </v-toolbar>
            <v-form @submit.prevent="submit" :disabled="!haveRole('productedit')">
                <v-card-text>
                    <!-- <p>{{$v.form.productComposition.$model}}</p>
                    <p>{{products}}</p> -->
                    <div>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.name.$model"
                                    :error-messages="nameErrors"
                                    label="Nombre*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    type="number"
                                    v-model="$v.form.salePrice.$model"
                                    :error-messages="salePriceErrors"
                                    label="Precio de venta (€)*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    disabled
                                    v-model="$v.form.code.$model"
                                    label="Código"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    counter
                                    outlined
                                    label="Descripción"
                                    v-model="$v.form.description.$model"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-checkbox
                                    v-model="$v.form.favourite.$model"
                                    label="Favorito"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-checkbox
                                    v-model="$v.form.ground.$model"
                                    label="Molido"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-checkbox
                                    v-model="$v.form.isMixed.$model"
                                    label="Mezclado"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-show="showProductComposition"
                                        v-model="
                                            $v.form.productComposition.$model
                                        "
                                        :items="products"
                                        :error-messages="
                                            showProductComposition
                                                ? productCompositionError
                                                : ''
                                        "
                                        outlined
                                        chips
                                        clearable
                                        deletable-chips
                                        label="Composición de productos"
                                        multiple
                                        item-text="name"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions v-if="haveRole('productedit')">
                    <v-btn
                        v-if="$v.form.id.$model && !form.deleted"
                        class="ml-4"
                        color="error"
                        elevation="0"
                        :loading="loading"
                        @click="remove"
                        >Eliminar</v-btn
                    >
                    <v-btn
                        v-if="$v.form.id.$model && form.deleted"
                        class="ml-4"
                        color="info"
                        elevation="0"
                        :loading="loading"
                        @click="restore"
                        >Recuperar</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-4"
                        color="primary"
                        elevation="0"
                        type="submit"
                        :loading="loading"
                        >Guardar</v-btn
                    >
                </v-card-actions>
            </v-form>
        </v-card>
    </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

import { validatePrice } from "../../validations";

import { mapMutations, mapGetters } from "vuex";
export default {
    name: "FormularioProductos",
    mixins: [validationMixin],
    props: {
        item: Object,
    },
    validations: {
        form: {
            id: {},
            name: { required },
            salePrice: {
                required,
                unitPricePattern(salePrice) {
                    return validatePrice(salePrice);
                },
            },
            code: {},
            description: {},
            favourite: {},
            ground: {},
            isMixed: {},
            productComposition: {
                required: requiredIf(function () {
                    return this.showProductComposition;
                }),
            },
        },
    },
    data() {
        return {
            form: {
                id: "",
                name: "",
                salePrice: "",
                code: "",
                description: "",
                favourite: "",
                ground: "",
                isMixed: "",
                productComposition: [],
            },
            products: [],
            loading: false,
        };
    },
    watch: {
        item() {
            this.form = this.item;
        },
    },
    mounted() {
        this.getProducts();
        if (this.$parent.$options.name == "NuevoProducto") {
            this.getNewCode();
        }
    },
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        showProductComposition() {
            return this.$v.form.ground.$model || this.$v.form.isMixed.$model;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.form.name.$dirty) return errors;
            !this.$v.form.name.required && errors.push("Obligatorio");
            return errors;
        },
        salePriceErrors() {
            const errors = [];
            if (!this.$v.form.salePrice.$dirty) return errors;
            !this.$v.form.salePrice.required && errors.push("Obligatorio");
            !this.$v.form.salePrice.unitPricePattern && errors.push("Debe introducir un precio válido");
            return errors;
        },
        productCompositionError() {
            const errors = [];
            if (!this.$v.form.productComposition.$dirty) return errors;
            !this.$v.form.productComposition.required &&
                errors.push("Obligatorio");
            return errors;
        },
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getProducts() {
            const { data } = await this.$http.get("/products", {
                params: { actualPage: 1, take: 9999999 },
            });
            this.products = data;
            // console.log(this.products);
        },
        async getNewCode() {
            if (!this.form.id) {
                const { data } = await this.$http.get("/products/code/new");
                this.form.code = data;
            }
        },
        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.setLoading(true);
                try {
                    if (!this.form.id) {
                        const { data } = await this.$http.post(
                            `/products`,
                            this.form
                        );
                        this.$router.push({
                            name: "editarProducto",
                            params: { id: data.id },
                        });
                    } else {
                        await this.$http.put(
                            `/products/${this.$route.params.id}`,
                            this.form
                        );
                    }
                    this.$toast.success("Guardado correctamente");
                } catch (error) {
                } finally {
                    this.setLoading(false);
                }
            }
        },
        async remove() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.delete("/products/" + this.form.id);
                    this.$router.push({ name: "productos" });
                    this.$toast.success("Eliminado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
        async restore() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.get("/products/restore/" + this.form.id);
                    this.form.deleted = false;
                    this.$toast.success("Recuperado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
    },
};
</script>

<style>
</style>