<template>
    <v-col cols="12" md="12">
        <v-card outlined>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Datos del proveedor</v-toolbar-title>
            </v-toolbar>
            <v-form @submit.prevent="submit">
                <v-card-text>
                    <div>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.companyName.$model"
                                    :error-messages="companyNameErrors"
                                    label="Nombre compañía*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.cifnif.$model"
                                    label="CIF/NIF"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.rsi.$model"
                                    label="Registro sanitario"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.name.$model"
                                    label="Nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.surname.$model"
                                    label="Apellidos"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.phone.$model"
                                    label="Teléfono"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.email.$model"
                                    :error-messages="emailErrors"
                                    label="Correo electrónico"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    autocomplete="off"
                                    outlined
                                    v-model="$v.form.address.$model"
                                    label="Dirección"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-autocomplete
                                    v-model="form.products"
                                    :items="products"
                                    chips
                                    deletable-chips
                                    outlined
                                    color="blue-grey lighten-2"
                                    label="Productos"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions v-if="haveRole('provideredit')">
                    <v-btn
                        v-if="$v.form.id.$model && !form.deleted"
                        class="ml-4"
                        color="error"
                        elevation="0"
                        :loading="loading"
                        @click="remove"
                        >Eliminar</v-btn
                    >
                    <v-btn
                        v-if="$v.form.id.$model && form.deleted"
                        class="ml-4"
                        color="info"
                        elevation="0"
                        :loading="loading"
                        @click="restore"
                        >Recuperar</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-4"
                        color="primary"
                        elevation="0"
                        type="submit"
                        :loading="loading"
                        >Guardar</v-btn
                    >
                    <!-- <v-btn @click="showDialog" color="default">Limpiar</v-btn> -->
                </v-card-actions>
            </v-form>
        </v-card>
    </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { mapMutations, mapGetters } from "vuex";
export default {
    mixins: [validationMixin],
    props: {
        item: Object,
    },
    validations: {
        form: {
            id: {},
            companyName: { required },
            cifnif: {},
            rsi: {},
            name: {},
            surname: {},
            phone: {},
            email: { email },
            address: {},
        },
        products: [],
    },
    data() {
        return {
            form: {
                id: "",
                companyName: "",
                cifnif: "",
                rsi: "",
                name: "",
                surname: "",
                phone: "",
                email: "",
                address: "",
            },
            products: [],
            loading: false,
        };
    },
    mounted() {
        this.getProducts();
    },
    watch: {
        item() {
            this.form = {
                id: this.item.id,
                companyName: this.item.companyName,
                cifnif: this.item.cifnif,
                rsi: this.item.rsi,
                name: this.item.name,
                surname: this.item.surname,
                phone: this.item.phone,
                email: this.item.email,
                address: this.item.address,
                products: this.item.products
            }
        },
    },
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        companyNameErrors() {
            const errors = [];
            if (!this.$v.form.companyName.$dirty) return errors;
            !this.$v.form.companyName.required && errors.push("Obligatorio");
            return errors;
        },
        /* cifnifErrors() {
            const errors = [];
            if (!this.$v.form.cifnif.$dirty) return errors;
            !this.$v.form.cifnif.required && errors.push("Obligatorio");
            return errors;
        }, */
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.email &&
                errors.push("Debe introducir un correo válido");
            return errors;
        },
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getProducts() {
            try {
                const { data } = await this.$http.get("/products", {
                    params: { actualPage: 1, take: 9999999 },
                });
                this.products = data;
            } catch (error) {}
        },
        async submit() {
            // console.log("form", this.form);
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.setLoading(true);
                try {
                    if (!this.form.id) {
                        const { data } = await this.$http.post(
                            `/providers`,
                            this.form
                        );
                        this.$router.push({
                            name: "editarProveedor",
                            params: { id: data.id },
                        });
                    } else {
                        await this.$http.put(
                            `/providers/${this.$route.params.id}`,
                            this.form
                        );
                    }
                    this.$toast.success("Guardado correctamente");
                } catch (error) {
                } finally {
                    this.setLoading(false);
                }
            }
        },
        async remove() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.delete("/providers/" + this.form.id);
                    this.$router.push({ name: "proveedores" });
                    this.$toast.success("Eliminado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
        async restore() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.get("/providers/restore/" + this.form.id);
                    this.form.deleted = false;
                    this.$toast.success("Recuperado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
    },
};
</script>

<style>
</style>