/* export async function getUser({ commit }) {
	try {
		const { data } = await this.$axios.get("http://localhost:3000/auth/me");
		console.log("user", data);
		commit("user/setUser", data, { root: true });
	} catch (error) {}
} */
import jwt_decode from "jwt-decode";

export async function signIn({ commit }, user) {
	try {
		commit("setLoading", true, { root: true });
        const {data} = await this.$axios.post("/auth/login", user);
        // console.log("data user", data);
        const userDecoded = jwt_decode(data.access_token);
        // console.log("data userDecoded", userDecoded);

		commit("setUser", userDecoded);
		commit("setAccessToken", data.access_token);
	} catch (e) {
        // console.log("eeeee", e);
		commit("authError", e.message);
	} finally {
		commit("setLoading", false, { root: true });
	}
}
