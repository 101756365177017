<template>
    <v-container fluid>
        <title-component title="Documentos"></title-component>

        <v-row class="pt-5">
            <!-- <p>{{$v}}</p> -->
            <v-col>
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step :complete="step > 1" step="1">
                            Documento
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="step > 2" step="2">
                            Filtros
                        </v-stepper-step>

                        <!-- <v-divider></v-divider>

                        <v-stepper-step step="3">Crear</v-stepper-step> -->
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-form>
                                <v-card flat class="mt-10 mb-10">
                                    <v-radio-group
                                        v-model="document"
                                        row
                                        @change="clearFilters"
                                    >
                                        <v-row>
                                            <v-col cols="2">
                                                <v-radio
                                                    label="Almacén"
                                                    value="almacen"
                                                ></v-radio>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-radio
                                                    label="Salidas"
                                                    value="salidas"
                                                ></v-radio>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-radio
                                                    label="Proveedores"
                                                    value="proveedores"
                                                ></v-radio>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-radio
                                                    label="Catálogo"
                                                    value="productos"
                                                ></v-radio>
                                            </v-col>
                                        </v-row>
                                    </v-radio-group>
                                </v-card>

                                <v-btn
                                    color="primary"
                                    x-large
                                    elevation="0"
                                    :disabled="$v.document.$invalid"
                                    @click="step = 2"
                                >
                                    Siguiente paso
                                </v-btn>
                            </v-form>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card flat class="mt-10 mb-10">
                                <v-row>
                                    <!-- {{ checkboxes }} -->
                                    <v-col
                                        cols="2"
                                        v-show="
                                            document == 'almacen' ||
                                            document == 'salidas'
                                        "
                                    >
                                        <v-checkbox
                                            :disabled="
                                                checkboxes.includes(
                                                    'numerolote'
                                                )
                                            "
                                            v-model="checkboxes"
                                            label="Rango de fechas"
                                            color="primary"
                                            value="rangofechas"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        v-show="
                                            document == 'almacen' ||
                                            document == 'salidas'
                                        "
                                    >
                                        <v-checkbox
                                            v-model="checkboxes"
                                            label="Número de lote"
                                            color="primary"
                                            value="numerolote"
                                            hide-details
                                            @change="changeCheckboxLot"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        v-show="
                                            document == 'almacen' ||
                                            document == 'salidas' ||
                                            document == 'productos'
                                        "
                                    >
                                        <v-checkbox
                                            :disabled="
                                                checkboxes.includes(
                                                    'numerolote'
                                                )
                                            "
                                            v-model="checkboxes"
                                            label="Producto"
                                            color="primary"
                                            value="producto"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        v-show="
                                            document == 'almacen' ||
                                            document == 'proveedores'
                                        "
                                    >
                                        <v-checkbox
                                            :disabled="
                                                checkboxes.includes(
                                                    'numerolote'
                                                )
                                            "
                                            v-model="checkboxes"
                                            label="Proveedor"
                                            color="primary"
                                            value="proveedor"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        v-show="document == 'salidas'"
                                    >
                                        <v-checkbox
                                            :disabled="
                                                checkboxes.includes(
                                                    'numerolote'
                                                )
                                            "
                                            v-model="checkboxes"
                                            label="Cliente"
                                            color="primary"
                                            value="cliente"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                flat
                                class="mt-10 mb-10"
                                v-show="checkboxes.includes('rangofechas')"
                            >
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-menu
                                            ref="menuFecha"
                                            v-model="menuFechaInicial"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="initDate"
                                                    outlined
                                                    label="Fecha de inicio"
                                                    persistent-hint
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :error-messages="
                                                        initDateErrors
                                                    "
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="form.initDate"
                                                @change="convertDate"
                                                @input="
                                                    menuFechaInicial = false
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-menu
                                            ref="menuFecha"
                                            v-model="menuFechaFin"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="endDate"
                                                    outlined
                                                    label="Fecha de finalización"
                                                    persistent-hint
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :error-messages="
                                                        endDateErrors
                                                    "
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="form.endDate"
                                                @change="convertDate"
                                                @input="menuFechaFin = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                flat
                                class="mt-10 mb-10"
                                v-show="checkboxes.includes('numerolote')"
                            >
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            autocomplete="off"
                                            outlined
                                            v-model="$v.form.lot.$model"
                                            label="Nº Lote*"
                                            :error-messages="lotErrors"
                                            @change="changeLot"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-show="document == 'almacen'"
                                    >
                                        <v-checkbox
                                            v-model="printMixGroundAndOutput"
                                            label="¿Añadir mezclas, moliendas y salidas?"
                                            color="primary"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card
                                flat
                                class="mt-10 mb-10"
                                v-show="checkboxes.includes('producto')"
                            >
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <custom-autocomplete-component
                                            v-model="$v.form.product.$model"
                                            outlined
                                            :query="queryProducts"
                                            label="Productos*"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="productErrors"
                                        ></custom-autocomplete-component>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card
                                flat
                                class="mt-10 mb-10"
                                v-show="checkboxes.includes('proveedor')"
                            >
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <custom-autocomplete-component
                                            v-model="$v.form.provider.$model"
                                            outlined
                                            :query="queryProviders"
                                            label="Proveedores*"
                                            item-text="companyName"
                                            item-value="id"
                                            :error-messages="providerErrors"
                                        ></custom-autocomplete-component>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card
                                flat
                                class="mt-10 mb-10"
                                v-show="checkboxes.includes('cliente')"
                            >
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <custom-autocomplete-component
                                            v-model="$v.form.client.$model"
                                            outlined
                                            :query="queryClients"
                                            label="Clientes*"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="clientErrors"
                                        ></custom-autocomplete-component>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-btn
                                color="primary"
                                x-large
                                elevation="0"
                                :loading="loadingSubmit"
                                @click="submit()"
                            >
                                Generar documento
                            </v-btn>

                            <v-btn
                                color="default"
                                x-large
                                elevation="0"
                                class="ml-5"
                                @click="step--"
                            >
                                Atrás
                            </v-btn>
                        </v-stepper-content>

                        <!-- <v-stepper-content step="3">
                            <v-card
                                class="mb-12"
                                color="grey lighten-1"
                                height="200px"
                            ></v-card>

                            <v-btn
                                color="primary"
                                x-large
                                elevation="0"
                                @click="submit()"
                            >
                                Generar documento
                            </v-btn>

                            <v-btn
                                color="default"
                                x-large
                                elevation="0"
                                class="ml-5"
                                @click="step--"
                            >
                                Atrás
                            </v-btn>
                        </v-stepper-content> -->
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import TitleComponent from "@/components/Title.vue";
import CustomAutocompleteComponent from "@/components/CustomAutocompleteComponent";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

import logoCaperucita from "@/assets/logo.jpg";

export default {
    mixins: [validationMixin],
    components: {
        TitleComponent,
        CustomAutocompleteComponent,
    },
    data() {
        return {
            img: logoCaperucita,

            queryProducts: "/products/getAllNormal",
            queryProviders: "/providers/getAll",
            queryClients: "/clients/getAllWithoutAlmacen",

            step: 1,
            document: null,
            checkboxes: [],
            rangedates: false,
            numlot: false,
            inputId: null,
            outputId: null,
            printMixGroundAndOutput: null,
            product: false,
            provider: false,
            client: false,
            menuFechaInicial: false,
            menuFechaFin: false,
            initDate: null, // moment(new Date()).format("DD/MM/YYYY"),
            endDate: null, //moment(new Date()).format("DD/MM/YYYY"),
            form: {
                initDate: null,
                endDate: null,
                lot: null,
                product: null,
                provider: null,
                client: null,
            },
            outputs: null,
            doc: null,
            total: null,
            totalPagesExp: "{total_pages_count_string}",
            headFunction: null,
            bodyFunction: null,
            loadingSubmit: false,
        };
    },
    validations: {
        document: { required },
        form: {
            lot: { required },
            product: { required },
            provider: { required },
            client: { required },
        },
    },
    watch: {
        checkboxes(){
            if(!this.checkboxes.includes('rangofechas')){
                this.initDate = null
                this.endDate = null
                this.form.initDate = null;
                this.form.iniendDatetDate = null;
            }
            if(!this.checkboxes.includes('numerolote')){
                this.form.lot = null;
            }
            if(!this.checkboxes.includes('producto')){
                this.form.product = null;
            }
            if(!this.checkboxes.includes('proveedor')){
                this.form.provider = null;
            }
            if(!this.checkboxes.includes('cliente')){
                this.form.client = null;
            }
        }
    },
    methods: {
        convertDate() {
            this.initDate = this.form.initDate
                ? moment(this.form.initDate).format("DD/MM/YYYY")
                : null;
            this.endDate = this.form.endDate
                ? moment(this.form.endDate).format("DD/MM/YYYY")
                : null;
        },
        headInput() {
            return [
                {
                    id: "ID",
                    lot: "Nº Lote",
                    product: "Producto",
                    weight: "Cantidad",
                    provider: "Proveedor",
                    date: "Fecha",
                },
            ];
        },

        async bodyInputAll() {
            this.total = 0
            var body = [];
            const { data } = await this.$http.get("/inputs", {
                params: {
                    take: 999999,
                    search: this.form.lot,
                    provider: this.form.provider,
                    product: this.form.product,
                    dateInitial: this.form.initDate,
                    dateEnd: this.form.endDate,
                },
            });
            data.forEach((input) => {
                this.total += input.weight;
                body.push({
                    id: input.id,
                    lot: input.lot,
                    product: input.product.name,
                    weight: input.weight + " g",
                    provider: input.provider.companyName,
                    date: input.createAt,
                });
            });
            return body;
        },
        headInputMixAndGround() {
            return [
                {
                    id: "ID",
                    lot: "Nº Lote",
                    product: "Producto",
                    weigth: "Cantidad",
                    type: "Tipo",
                    date: "Fecha",
                },
            ];
        },

        async bodyInputMixAndGround() {
            var body = [];
            const { data } = await this.$http.get(
                "/inputs/getFullOneWithOutputs/" + this.inputId
            );
            var movements = [];
            movements = data.composition;
            this.outputs = data.outputs;
            movements.forEach((movement) => {
                body.push({
                    id: movement.id,
                    lot: movement.input.lot,
                    product: movement.input.product.name,
                    weigth: movement.weight + " g",
                    type: movement.input.productTransformation.name,
                    date: movement.createAt,
                });
            });
            return body;
            /* return new Promise((resolve, reject) => {
			console.log('APLICO EL INPUTID', this.inputId);

			this.movementsInputsService.getMixAndGround(this.inputId).subscribe((movements: object[]) => {
				console.log('bodyInputMixAndGround', movements);
				let body = [];
				movements.forEach((movement: any) => {
					body.push({
						id: movement.targetInput.id,
						lot: movement.targetInput.lot,
						product: movement.targetInput.product.name,
						weigth: movement.weight + ' g',
						type: movement.productsTransformation.name,
						date: this.datePipe.transform(movement.targetInput.createAt, 'dd/MM/yyyy')
					});
				});

				resolve(body);
			});
		}); */
        },

        headInputOutput() {
            return [
                {
                    id: "ID",
                    lot: "Nº Lote",
                    product: "Producto",
                    weight: "Cantidad",
                    client: "Cliente",
                    date: "Fecha",
                },
            ];
        },

        bodyInputOutput() {
            var body = []
            this.outputs.forEach(o => {
                body.push({
                    id: o.id,
                    lot: o.input.lot,
                    product: o.input.product.name,
                    weight: o.weight,
                    client: o.client.name,
                    date: o.createAt
                })
            })
            return body;
        },
        //Obtenemos una entrada por numero de lote, PENDIENTE
        //CREO DEBO QUITARLO 11/06/2021
        async bodyInputOne() {
            var body = [];
            const { data } = await this.$http.get(
                "/inputs/lot/" + this.form.lot
            );
            var input = null;
            input = data;
            this.inputId = input.id;
            body.push({
                id: input.id,
                lot: input.lot,
                product: input.product.name,
                weight: input.weight + " g",
                provider: input.provider.companyName,
                date: input.createAt,
            });
            return body;
            /* return new Promise((resolve, reject) => {
			this.inputService.getOneByLot(this.secondFormGroup.get('lot').value).subscribe((input: Input) => {
				//this.inputId = input.id;
				console.log('RECOJO EL INPUTID', this.inputId);

				let body = [];
				body.push({
					id: input.id,
					lot: input.lot,
                    product: input.product.name,
                    weight: input.weight+' g',
					provider: input.provider.companyName,
					date: this.datePipe.transform(input.createAt, 'dd/MM/yyyy')
				});

				resolve(body);
			});
		}); */
        },
        headOutput() {
            return [
                {
                    id: "ID",
                    lot: "Nº Lote",
                    product: "Producto",
                    weight: "Cantidad",
                    client: "Cliente",
                    date: "Fecha",
                },
            ];
        },

        async bodyOutputAll() {
            this.total = 0
            var body = [];
            const { data } = await this.$http.get("/outputs", {
                params: {
                    take: 999999,
                    search: this.form.lot,
                    provider: this.form.provider,
                    product: this.form.product,
                    client: this.form.client,
                    dateInitial: this.form.initDate,
                    dateEnd: this.form.endDate,
                },
            });
            data.forEach((output) => {
                this.total += output.weight;
                body.push({
                    id: output.id,
                    lot: output.input.lot,
                    product: output.input.product.name,
                    weight: output.weight + " g",
                    client: output.client.name,
                    date: output.createAt,
                });
            });
            return body;
            /* return new Promise((resolve, reject) => {
			this.outputService
				.getOutputs(
					'',
					'',
					this.secondFormGroup.get('lot').value,
					this.secondFormGroup.get('client').value,
                    this.secondFormGroup.get('product').value,
                    (this.secondFormGroup.get('dateInit').value ? moment(this.secondFormGroup.get('dateInit').value, 'DD/MM/YYYY').format('YYYY/MM/DD') : ''),
					(this.secondFormGroup.get('dateEnd').value ? moment(this.secondFormGroup.get('dateEnd').value, 'DD/MM/YYYY').format('YYYY/MM/DD') : '')
				)
				.subscribe((outputs: [[], number]) => {
					console.log('input', outputs);
					let body = [];
					outputs[0].forEach((output: any) => {
						body.push({
							id: output.id,
							lot: output.lot,
                            product: output.product.name,
                            weight: output.weight+' g',
							client: output.client.name,
							date: this.datePipe.transform(output.createAt, 'dd/MM/yyyy')
                        });
                        //Calculamos el peso total de las salidas
                        this.outputTotalWeight += output.weight;
					});

					resolve(body);
				});
		}); */
        },
        headProduct() {
            return [
                {
                    id: "ID",
                    name: "Nombre",
                    salePrice: "Precio venta",
                    favourite: "Favorito",
                    ground: "Molido",
                    isMixed: "Mezclado",
                },
            ];
        },

        async bodyProductAll() {
            var body = [];
            const { data } = await this.$http.get("/products", {
                params: { take: 99999 },
            });
            data.forEach((product) => {
                body.push({
                    id: product.id,
                    name: product.name,
                    salePrice: product.salePrice,
                    favourite: product.favourite ? "Si" : "No",
                    ground: product.ground ? "Si" : "No",
                    isMixed: product.isMixed ? "Si" : "No",
                });
            });
            return body;
        },
        async bodyProductOne() {
            var body = [];
            const { data } = await this.$http.get(
                `/products/${this.form.product}`
            );
            var product = data;
            body.push({
                id: product.id,
                name: product.name,
                salePrice: product.salePrice,
                favourite: product.favourite ? "Si" : "No",
                ground: product.ground ? "Si" : "No",
                isMixed: product.isMixed ? "Si" : "No",
            });
            return body;
            /* return new Promise((resolve, reject) => {
			this.productService.getOne(this.secondFormGroup.get('product').value).subscribe((product: Product) => {
				let body = [];
				body.push({
					id: product.id,
					name: product.name,
					salePrice: product.salePrice,
					favourite: product.favourite ? 'Si' : 'No',
					ground: product.ground ? 'Si' : 'No'
				});

				resolve(body);
			});
		}); */
        },
        headProvider() {
            return [
                {
                    id: "ID",
                    companyName: "Nombre empresa",
                    cifnif: "CIF/NIF",
                    name: "Nombre",
                    surname: "Apellidos",
                    phone: "Teléfono",
                    email: "Correo",
                },
            ];
        },
        async bodyProviderAll() {
            var body = [];
            const { data } = await this.$http.get("/providers", {
                params: { take: 99999 },
            });
            data.forEach((provider) => {
                body.push({
                    id: provider.id,
                    companyName: provider.companyName,
                    cifnif: provider.cifnif,
                    name: provider.name,
                    surname: provider.surname,
                    phone: provider.phone,
                    email: provider.email,
                });
            });
            return body;
        },
        async bodyProviderOne() {
            var body = [];
            const { data } = await this.$http.get(
                `/providers/${this.form.provider}`
            );
            var provider = data;
            body.push({
                id: provider.id,
                companyName: provider.companyName,
                cifnif: provider.cifnif,
                name: provider.name,
                surname: provider.surname,
                phone: provider.phone,
                email: provider.email,
            });
            return body;
            /* return new Promise((resolve, reject) => {
			this.providerService.getOne(this.secondFormGroup.get('provider').value).subscribe((provider: Provider) => {
				let body = [];
				body.push({
					id: provider.id,
					companyName: provider.companyName,
					cifnif: provider.cifnif,
					name: provider.name,
					surname: provider.surname,
					phone: provider.phone,
					email: provider.email
				});

				resolve(body);
			});
		}); */
        },
        async createPDF() {
            var today = moment(new Date()).format("DD/MM/YYYY");
            this.doc = new jsPDF();

            this.doc.autoTable({
                head: this.headFunction,
                body: await this.bodyFunction,
                didDrawPage: (data) => {
                    // Header
                    this.doc.setFontSize(20);
                    this.doc.setTextColor(40);
                    //this.doc.setFontStyle("normal");

                    if (this.img) {
                        this.doc.addImage(
                            this.img,
                            "JPG",
                            data.settings.margin.left,
                            10,
                            13.5,
                            19.9
                        );
                    }
                    this.doc.text(
                        "Especias Caperucita Roja    Fecha: " + today,
                        data.settings.margin.left + 22,
                        23
                    );

                    

                    // Footer
                    var str = "Página " + this.doc.internal.getNumberOfPages();
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof this.doc.putTotalPages === "function") {
                        str = str + " de " + this.totalPagesExp;
                    }
                    this.doc.setFontSize(10);

                    // console.log(
                    //     "tamaño pagina",
                    //     this.doc.internal.pageSize.getHeight()
                    // );

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    var pageSize = this.doc.internal.pageSize;
                    var pageHeight = pageSize.height
                        ? pageSize.height
                        : pageSize.getHeight();

                    //Si tenemos total, imprimimos un rectacgulo con la cantidad total dentro
                    if(this.total){
                        this.doc.setFillColor(79,157,159);
                        this.doc.rect(data.settings.margin.left, pageHeight - 16, 182, 10, 'F');
                        
                        this.doc.setTextColor(255, 255, 255);
                        this.doc.text(150, pageHeight - 10, "Total: " + this.total + "g");
                    }
                        
                        this.doc.text(
                        str,
                        data.settings.margin.left + 10,
                        pageHeight - 10
                    );
                },
                margin: { top: 30 },
            });

            if (this.printMixGroundAndOutput) {
                let finalY = this.doc.previousAutoTable.finalY;
                this.doc.text("Mezclas y Moliendas", 14, finalY + 15);
                this.doc.autoTable({
                    startY: finalY + 20,
                    head: this.headMixAndGroundFunction,
                    body: await this.bodyMixAndGroundFunction,
                });

                finalY = this.doc.previousAutoTable.finalY;
                this.doc.text("Salidas", 14, finalY + 15);
                this.doc.autoTable({
                    startY: finalY + 20,
                    head: this.headInputOutputFunction,
                    body: await this.bodyInputOutputFunction,
                });
            }
            /* if (this.outputTotalWeight > 0) {
                let finalY = this.doc.previousAutoTable.finalY;
                this.doc.text(
                    "Cantidad total: " + this.outputTotalWeight + "g",
                    195,
                    finalY + 15,
                    "right"
                );
            } */

            // Total page number plugin only available in jspdf v1.0+
            if (typeof this.doc.putTotalPages === "function") {
                this.doc.putTotalPages(this.totalPagesExp);
            }

            this.generatePDF();
        },
        generatePDF() {
            //Descarga el pdf
            //this.doc.open('a4.pdf');

            this.doc.save("caperucita.pdf");
            this.loadingSubmit = false;

            //Abre en nueva pestaña el pdf
            //this.doc.output('dataurlnewwindow');

            /* //Resetear todos los campos
            this.disableAllCheckbox();
            //Aplicamos los filtros, al haber deshabilitado previamente, los vacía.
            this.checkFilterDates();
            this.checkFilterLot();
            this.checkFilterProduct();
            this.checkFilterProvider();
            this.checkFilterClient();

            this.outputTotalWeight = 0; */
        },
        async submit() {
            this.loadingSubmit = true;
            // console.log(typeof this.document);
            switch (this.document) {
                case "almacen": //Almacén
                    this.headFunction = this.headInput();
                    if (this.checkboxes.includes("numerolote")) {
                        // console.log("1");
                        this.bodyFunction = await this.bodyInputOne();
                        // console.log("2");
                        if (this.printMixGroundAndOutput) {
                            //Mezclas y moliendas
                            this.headMixAndGroundFunction =
                                this.headInputMixAndGround();
                            this.bodyMixAndGroundFunction =
                                await this.bodyInputMixAndGround();
                            //Salidas
                            this.headInputOutputFunction = this.headInputOutput();
                            this.bodyInputOutputFunction = this.bodyInputOutput();
                        }
                    } else {
                        this.bodyFunction = this.bodyInputAll();
                    }
                    this.createPDF();
                    break;

                case "salidas": //Salidas
                    this.headFunction = this.headOutput();
                    this.bodyFunction = this.bodyOutputAll();
                    this.createPDF();
                    break;

                case "proveedores": //Proveedores
                    if (this.checkboxes.includes("proveedor")) {
                        this.bodyFunction = this.bodyProviderOne();
                    } else {
                        this.bodyFunction = this.bodyProviderAll();
                    }
                    this.headFunction = this.headProvider();
                    this.createPDF();
                    break;

                case "productos": //Productos
                    // console.log("Productos");
                    if (this.checkboxes.includes("producto")) {
                        this.bodyFunction = this.bodyProductOne();
                    } else {
                        this.bodyFunction = this.bodyProductAll();
                    }
                    this.headFunction = this.headProduct();
                    this.createPDF();
                    break;

                default:
                    // console.log("default");
                    break;
            }
        },
        changeCheckboxLot() {
            if (this.checkboxes.includes("numerolote")) {
                this.checkboxes = ["numerolote"];
                this.form.product = null;
                this.form.provider = null;
                this.form.client = null;
            }
        },
        async changeLot() {
            //Comprobamos si existe el número de lote
            var params = {
                numLot: this.form.lot,
            };
            const { data } = await this.$http.get(`/inputs/exist/lot`, {
                params: params,
            });
            if (!data) {
                this.$toast.warning("No existe el lote");
            }
        },
        clearFilters() {
            this.checkboxes = [];
            this.form = {
                initDate: null,
                endDate: null,
                lot: null,
                product: null,
                provider: null,
                client: null,
            };
            this.total = null
        },
    },
    computed: {
        initDateErrors() {
            const errors = [];
            /*if (!this.$v.form.createAt.$dirty) return errors;
            !this.$v.form.createAt.required && errors.push("Obligatorio"); */
            return errors;
        },
        endDateErrors() {
            return [];
        },
        lotErrors() {
            const errors = [];
            if (!this.$v.form.lot.$dirty) return errors;
            !this.$v.form.lot.required && errors.push("Obligatorio");
            return errors;
        },
        providerErrors() {
            const errors = [];
            if (!this.$v.form.provider.$dirty) return errors;
            !this.$v.form.provider.required && errors.push("Obligatorio");
            return errors;
        },
        productErrors() {
            const errors = [];
            if (!this.$v.form.product.$dirty) return errors;
            !this.$v.form.product.required && errors.push("Obligatorio");
            return errors;
        },
        clientErrors() {
            const errors = [];
            if (!this.$v.form.client.$dirty) return errors;
            !this.$v.form.client.required && errors.push("Obligatorio");
            return errors;
        },
    },
};
</script>

<style>
</style>