<template>
    <v-container fluid>
        <title-component title="Editar proveedor"></title-component>
        <form-proveedores :item="proveedor"></form-proveedores>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormProveedores from "./Form"

export default {
    components: {
        TitleComponent,
        FormProveedores
    },
    data() {
        return {
            proveedor: {},
        };
    },
    mounted() {
        this.getProvider();
    },
    methods: {
        async getProvider() {
            try {
                const { data } = await this.$http.get(
                    `/providers/${this.$route.params.id}`
                );
                var tmpProductsArr = []
                data.products.forEach(product => {
                    tmpProductsArr.push(product.id)
                });
                data.products = tmpProductsArr;
                this.proveedor = data;
                // console.log("provvvv", data);
            } catch (error) {}
        },
    },
};
</script>

<style>
</style>