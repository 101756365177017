<template>
    <v-dialog v-bind="{ ...$props, ...$attrs }" v-model="active" persistent @click:outside="$emit('close')">
        <slot></slot>
    </v-dialog>
</template>

<script>
export default {
    props: {
        active: {
            default: false,
        },
    },
};
</script>

<style>
.v-dialog {
    box-shadow: none;
}
</style>
