import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import Almacen from "../views/almacen/List.vue";
import NuevaEntrada from "../views/almacen/New.vue";
import EditarEntrada from "../views/almacen/Edit.vue";
import Salidas from "../views/salidas/List.vue";
import NuevaSalida from "../views/salidas/New.vue";
import EditarSalida from "../views/salidas/Edit.vue";
import Login from "../views/Login.vue";
import Proveedores from "../views/proveedores/List.vue";
import NuevoProveedor from "../views/proveedores/New.vue";
import EditarProveedor from "../views/proveedores/Edit.vue";
import Productos from "../views/productos/List.vue";
import NuevoProducto from "../views/productos/New.vue";
import EditarProducto from "../views/productos/Edit.vue";
import Usuarios from "../views/usuarios/List.vue";
import EditarUsuario from "../views/usuarios/Edit.vue";
import Documentos from "../views/Documentos.vue";
//import Public from "../Public.vue";
//import App from "../App";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "login",
		component: Login,
	},

	{
		path: "/almacen",
		name: "almacen",
		component: Almacen,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
    },
    {
        path: "/almacen/nuevo",
        name: "nuevaEntrada",
        component: NuevaEntrada,
    },
    {
        path: "/almacen/:id",
        name: "editarEntrada",
        component: EditarEntrada,
    },
	{
		path: "/salidas/listado",
		name: "salidas",
		component: Salidas,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
	},
    {
		path: "/salidas",
		name: "nuevaSalida",
		component: NuevaSalida
	},
    {
		path: "/salidas/:id",
		name: "editarSalida",
		component: EditarSalida
	},
	{
		path: "/proveedores",
		name: "proveedores",
		component: Proveedores,
    },
    {
        path: "/proveedores/nuevo",
        name: "nuevoProveedor",
        component: NuevoProveedor,
    },
    {
        path: "/proveedores/:id",
        name: "editarProveedor",
        component: EditarProveedor,
    },
	{
		path: "/productos",
		name: "productos",
		component: Productos,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
    },
    {
		path: "/productos/nuevo",
		name: "nuevoProducto",
		component: NuevoProducto,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
    },
    {
		path: "/productos/:id",
		name: "editarProducto",
		component: EditarProducto,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
	},
	{
		path: "/usuarios",
		name: "usuarios",
		component: Usuarios,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
    },
    {
		path: "/usuarios/:id",
		name: "editarUsuario",
		component: EditarUsuario,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
	},
	{
		path: "/documentos",
		name: "documentos",
		component: Documentos,
		meta: {
			requiresAuth: true,
			is_admin: true,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	linkActiveClass: "is-active",
	routes,
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const isLogged = store.state.auth.isLogged;
	// console.log(requiresAuth, isLogged, to.path);
	if (!requiresAuth && isLogged && to.path === "/login") {
		return next("/almacen");
	}
	if (requiresAuth && !isLogged) {
		next("/login");
	} else {
		next();
	}
});

export default router;
