<template>
    <v-container fluid>

        <title-component title="Usuarios"></title-component>
        <v-row class="pt-5">
            <v-col v-for="user in users" :key="user.id">
                <user-card :user="user"></user-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import UserCard from "@/components/UserCard.vue";

import { mapMutations } from "vuex";
export default {
    name: "Usuarios",
    components: {
        TitleComponent,
        UserCard,
    },
    data() {
        return {
            users: [],
        };
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getUsers() {
            try {
                this.setLoading(true);
                const { data } = await this.$http.get("/users");
                // console.log("users", data);
                this.users = data;
            } catch (error) {
            } finally {
                this.setLoading(false);
            }
        },
    },
};
</script>

<style>
</style>