function validatePrice(price) {
    if (!price) return true;
    if(price > 999999999999.99) return false;
    return /^[0-9]+([.,])?(\d{1,2})?$/.test(price);
}

/* function validatePercent(percent) {
    if (!percent) return true;
    return /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/.test(percent);
} */

function validateInteger(number) {
    if(!number) return true;
    return /^-?[0-9]*$/.test(number);
}

/* function validateCif(cif) {
    if (!cif || cif.length !== 9) {
        return false;
    }

    var letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var i;
    var digit;

    if (!letter.match(/[A-Z]/)) {
        return false;
    }

    for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);

        if (isNaN(digit)) {
            return false;
        }

        if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
                digit = parseInt(digit / 10) + (digit % 10);
            }

            sum += digit;
        } else {
            sum += digit;
        }
    }

    sum %= 10;
    if (sum !== 0) {
        digit = 10 - sum;
    } else {
        digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
}

function validateWeb(url) {
    if (!url) return true;
    return /^[a-zA-Z0-9]?[a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(url);
}

function formatToValidFloat(number) {
    //Eliminamos los puntos
    number = number.replaceAll('.', '').replaceAll(',', '.');
    if(NaN){
        return;
    }
    return number;
}

function formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
} */

export { validatePrice, /* validatePercent, */ validateInteger, /* validateCif, validateWeb , formatToValidFloat, formatDate */};
