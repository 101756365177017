<template>
    <v-container fluid>
        <title-component title="Editar entrada de almacén"></title-component>
        <form-entradas :item="entrada" @refreshInput="getInput"></form-entradas>
        <!-- <p>entrada: {{entrada}}</p> -->
        <!-- <composition-form
            v-if="entrada && mezclaOMolienda"
            :input="entrada"
        ></composition-form> -->
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormEntradas from "./Form";
/* import CompositionForm from "@/views/almacen/CompositionForm"; */
import { mapMutations } from "vuex";
import moment from "moment";

export default {
    components: {
        TitleComponent,
        FormEntradas,
        /* CompositionForm, */
    },
    data() {
        return {
            entrada: null,
        };
    },
    mounted() {
        this.getInput();
    },
    computed: {
        /* mezclaOMolienda() {
            return this.entrada.productsTransformation.id != 1;
        }, */
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getInput() {
            try {
                this.setLoading(true);
                const { data } = await this.$http.get(
                    `/inputs/${this.$route.params.id}`
                );
                this.entrada = data;
                this.entrada.createAt = moment(
                    this.entrada.createAt,
                    "DD/MM/YYYY"
                ).format("YYYY-MM-DD");
                // console.log("entrada", data);
            } catch (error) {

            }finally{
                this.setLoading(false);
            }
        },
    },
};
</script>

<style>
</style>