<template>
    <v-container
        fluid
        fill-height
        :style="`background-image: url(${require('../../public/img/web/bg_login.jpg')})`"
        class="bg-image"
    >
        <v-row justify="center" class="fill-height">
            <v-col xs="12" sm="12" md="6" xl="4" align-self="center">
                <v-alert type="error" class="mt-5" v-if="error">{{
                    errorMessage
                }}</v-alert>

                <v-card elevation="5">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title class="headline">Iniciar sesión</v-toolbar-title>
                    </v-toolbar>
                    <v-form @submit.prevent="submit()">
                        <v-card-text>
                            <div>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            prepend-icon="mdi-account"
                                            outlined
                                            v-model="$v.form.username.$model"
                                            :error-messages="nameErrors"
                                            label="Usuario"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="$v.form.password.$model"
                                            prepend-icon="mdi-lock"
                                            outlined
                                            :append-icon="
                                                showPassword
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            :error-messages="passwordErrors"
                                            :type="
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            label="Contraseña"
                                            required
                                            @click:append="
                                                showPassword = !showPassword
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                x-large
                                elevation="0"
                                @click="clear"
                                color="secundary"
                            >
                                Limpiar
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                x-large
                                elevation="0"
                                color="primary"
                                type="submit"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

export default {
    validations: {
        form: {
            username: { required },
            password: { required, minLength: minLength(8) },
        },
    },

    data: () => ({
        showPassword: false,
        form: {
            username: null,
            password: null,
        },
        alignments: ["start", "center", "end"],
    }),

    computed: {
        ...mapState("auth", ["error", "errorMessage"]),
        nameErrors() {
            const errors = [];
            if (!this.$v.form.username.$dirty) return errors;
            !this.$v.form.username.required &&
                errors.push("El usuario es obligatorio");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;
            !this.$v.form.password.required &&
                errors.push("La contraseña es obligatoria");
            !this.$v.form.password.minLength &&
                errors.push("La contraseña debe tener al menos 8 caracteres");
            return errors;
        },
    },

    methods: {
        ...mapActions("auth", ["signIn"]),
        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.signIn(this.form);
                // console.log("login");
                this.$router.push("/almacen");
            }
        },
        clear() {
            this.$v.$reset();
            this.form = {
                username: "",
                password: "",
            }
        },
    },
};
</script>

<style>
.bg-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
}
</style>