<template>
    <div>
        <v-data-table
            v-bind="$attrs"
            :headers="headers"
            :items="items"
            :items-per-page="999999"
            :hide-default-footer="true"
            mobile-breakpoint="800"
            :item-class="backgroundIfWeight"
        >
            <!-- @click:row="selectRow" -->
            <template v-slot:[`item.weight`]="{ item }">
                {{ item.weight ? item.weight + " g" : "" }}
            </template>
            <template v-slot:[`item.remaining`]="{ item }">
                {{
                    item.remaining || item.remaining == 0
                        ? item.remaining + " g"
                        : ""
                }}
            </template>

            <template v-slot:[`item.price`]="{ item }">
                {{ item.price ? item.price + " €" : "" }}
            </template>

            <template v-slot:[`item.salePrice`]="{ item }">
                {{ item.salePrice ? item.salePrice + " €" : "" }}
            </template>

            <template v-slot:[`item.info`]="{ item }">
                <v-btn small fab elevation="0" @click="$emit('info', item.id)">
                    <v-icon color="primary"> mdi-information-variant </v-icon>
                </v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    v-if="selectable && item.inputState.id != 1"
                    :color="checkSelectedItems(item.id) ? 'success' : 'default'"
                    elevation="0"
                    @click="$emit('selectButtonItem', item)"
                    >{{
                        checkSelectedItems(item.id)
                            ? "Seleccionado"
                            : type == "entrada"
                            ? "Seleccionar"
                            : "Crear Salida"
                    }}</v-btn
                >
                <v-btn
                    v-if="selectable && item.inputState.id == 2"
                    color="info"
                    elevation="0"
                    class="ml-2"
                    @click="$emit('finishInput', item)"
                >
                    Finalizar
                </v-btn>
                <v-btn
                    v-if="selectable && item.inputState.id == 1"
                    color="info"
                    elevation="0"
                    @click="$emit('putToUse', item)"
                >
                    Poner en uso
                </v-btn>
                <v-btn
                    v-if="!selectable && haveRole(roleEdit)"
                    :disabled="
                        'targetInput' in item && item.targetInput != null
                    "
                    color="success"
                    elevation="0"
                    @click.stop="selectEdit(item)"
                >
                    Editar
                </v-btn>
               <v-btn v-if="item.deleted && haveRole('inputrestore')" class="ml-2" small fab elevation="0">
                    <v-icon color="red"> mdi-trash-can </v-icon>
                </v-btn>
                <v-btn
                    v-if="item.client && item.client.id == 5 && item.targetInput && haveRole('inputedit')"
                    class="mt-2"
                    color="warning"
                    elevation="0"
                    @click.stop="goEditInput(item.targetInput.id)"
                >
                    Ver entrada
                </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-data-table
                        :headers="[
                            { text: 'Total', value: 'weight' },
                            { text: 'Precio', value: 'price' },
                            { text: 'Cliente', value: 'client.name' },
                            { text: 'Fecha', value: 'createAt' },
                        ]"
                        :items="item.outputs"
                        :items-per-page="999999"
                        :item-class="expandedBackground"
                        :hide-default-footer="true"
                    >
                            <!-- { text: 'Acciones', value: 'actions' }, -->
                        <!-- <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editOutput(item)">
                                mdi-pencil
                            </v-icon>
                        </template> -->
                    </v-data-table>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            default: "entrada",
        },
        /* params: {
            type: Object,
            default: () => {
                return {
                    actualPage: 1,
                    take: 20,
                };
            },
        }, */
        /* query: {
            type: String,
            required: true,
        }, */
        selectedItems: {
            type: Array,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        editPathName: {
            type: String,
        },
        roleEdit: {
            type: String,
        },
    },
    data() {
        return {
            selectedItemId: null,
            /* items: [], */
        };
    },
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        canSelectRow() {
            return this.selectable;
        },
    },
    methods: {
        backgroundIfWeight: (item) => {
            if (item.remainingPercent > 20) return "bg-success";
            if (item.remainingPercent <= 20 && item.remainingPercent > 10)
                return "bg-warn";
            if (item.remainingPercent <= 10 && item.remainingPercent >= 0)
                return "bg-error";
            if (item.remainingPercent < 0)
                return "bg-negative"
            
        },
        expandedBackground: () => {
            return "expanded-bg";
        },
        checkSelectedItems(idItem) {
            // console.log(idItem);
            return this.selectedItems.find((si) => si.input.id == idItem);
        },
        selectEdit(item) {
            this.$router.push({
                name: this.editPathName,
                params: { id: item.id },
            });
            // console.log("Editar seleccionado=", item);
        },
        /* selectRow(item) {
            // console.log("selectRow=", item);
            this.$emit("click:row", item.id);
        }, */
        goEditInput(targetInputId){
            this.$router.push({
                name: "editarEntrada",
                params: { id: targetInputId },
            });
        },
        /* editOutput(item){
            console.log("O===", item);
            this.$emit("editOutput", item)
        } */
    },
};
</script>

<style lang="scss">
.bg-success {
    color: white;
    background: #1abc9c;
}
.bg-success:hover {
    background: gray !important;
}

.bg-warn {
    color: white;
    background: #e67e22;
}
.bg-error {
    color: white;
    background: #d41e1e;
}

.bg-negative {
    color: rgb(255, 255, 255);
    background: #9400ff;
}

.expanded-bg {
    color: white;
    background: #3498db;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
    background: #4f9d9f !important;
}

.v-data-table__expanded__content > td {
    padding: 0 !important;
}
</style>