<template>
    <v-container fluid>
        <modal-component
            :active="fullInput != null"
            max-width="1400px"
            @close="fullInput = null"
        >
            <v-card v-if="fullInput" color="grey lighten-4">
                <v-card-title class="white--text bg-ecr-verde">
                    <v-col cols="auto"> Lote: {{ fullInput.lot }} </v-col>
                    <v-col cols="auto" class="ml-5">
                        Total: {{ fullInput.weight }} g
                    </v-col>
                    <v-col cols="auto" class="ml-5">
                        Restante: {{ fullInput.remaining }} g
                    </v-col>
                    <v-spacer> </v-spacer>
                    <v-btn
                        small
                        fab
                        elevation="0"
                        color="default"
                        @click="fullInput = null"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-card class="mt-3" outlined>
                        <v-card-title class="white--text bg-primary"
                            >Entrada</v-card-title
                        >
                        <v-card-text>
                            <table-component
                                :headers="[
                                    { text: 'Producto', value: 'product.name' },
                                    { text: 'Total', value: 'weight' },
                                    { text: 'Restante', value: 'remaining' },
                                    {
                                        text: 'Proveedor',
                                        value: 'provider.companyName',
                                    },
                                    {
                                        text: 'Estado',
                                        value: 'inputState.name',
                                    },
                                    { text: 'Fecha', value: 'createAt' },
                                ]"
                                :items="[fullInput]"
                            >
                            </table-component>
                        </v-card-text>
                    </v-card>
                    <v-card
                        class="mt-3"
                        outlined
                        v-if="fullInput && fullInput.composition.length"
                    >
                        <v-card-title class="white--text bg-primary">
                            Composición
                        </v-card-title>
                        <v-card-text>
                            <table-component
                                :headers="[
                                    { text: 'Producto', value: 'input.product.name' },
                                    { text: 'Cantidad', value: 'weight' },
                                    {
                                        text: 'Cliente',
                                        value: 'client.name',
                                    },
                                    { text: 'Fecha', value: 'createAt' },
                                ]"
                                :items="fullInput.composition"
                            >
                            </table-component>
                        </v-card-text>
                    </v-card>
                    <v-card
                        class="mt-3"
                        outlined
                        v-if="fullInput && fullInput.outputs.length"
                    >
                        <v-card-title class="white--text bg-primary"
                            >Salidas</v-card-title
                        >
                        <v-card-text>
                            <table-component
                                :headers="[
                                    { text: 'Cantidad', value: 'weight' },
                                    {
                                        text: 'Cliente',
                                        value: 'client.name',
                                    },
                                    { text: 'Fecha', value: 'createAt' },
                                ]"
                                :items="fullInput.outputs"
                            >
                            </table-component>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </modal-component>

        <title-component
            title="Almacén"
            pathCreate="nuevaEntrada"
            roleCreate="inputedit"
        ></title-component>

        <filter-component @search="search"></filter-component>

        <v-row class="mt-5">
            <v-col>
            <table-component
                :headers="[
                    {
                        text: 'Info.',
                        sortable: false,
                        value: 'info',
                    },
                    {
                        text: 'Lote',
                        align: 'start',
                        sortable: false,
                        value: 'lot',
                    },
                    { text: 'Producto', value: 'product.name' },
                    { text: 'Total', value: 'weight' },
                    { text: 'Restante', value: 'remaining' },
                    { text: 'Precio compra', value: 'price' },
                    { text: 'Precio venta', value: 'salePrice' },
                    { text: 'Proveedor', value: 'provider.companyName' },
                    { text: 'Estado', value: 'inputState.name' },
                    { text: 'Fecha', value: 'createAt' },
                    { text: 'Acciones', value: 'actions' },
                ]"
                :items="items"
                editPathName="editarEntrada"
                roleEdit="inputedit"
                @info="getFullInput"
            >
            </table-component>
            </v-col>
        </v-row>
        <infinite-loading
            ref="infiniteLoading"
            @infinite="getInfiniteItems"
            class="mt-5"
        >
            <div slot="no-more">No hay más elementos</div>
        </infinite-loading>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";

import { mapMutations } from "vuex";

export default {
    name: "Almacen",
    components: {
        TitleComponent,
        FilterComponent,
        TableComponent,
        ModalComponent,
    },
    data() {
        return {
            query: "/inputs",
            sheet: false,
            items: [],
            params: {
                actualPage: 1,
                take: 20,
                search: "",
                state: [1,2],
            },
            fullInput: null,
        };
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getItems() {
            const { data } = await this.$http.get(this.query, {
                params: this.params,
            });

            this.items = this.items.concat(data);
            return data;
        },
        async getInfiniteItems($state) {
            try {
                var items = await this.getItems();
                $state.loaded();
                if (items.length == 0) $state.complete();
                else this.params.actualPage++;
            } catch (error) {}
        },
        async search() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.items = [];
                this.params.actualPage = 1;
                /* await this.getItems();
                this.params.actualPage++; */
            } catch (error) {}
        },
        async clearFilters() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.items = [];
                this.params = {
                    actualPage: 1,
                    take: 20,
                    search: "",
                    state: [1,2],
                };
            } catch (error) {}
        },
        async getFullInput(idInput) {
            try {
                this.setLoading(true)
                const { data } = await this.$http.get(
                    `/inputs/getFullOneWithOutputs/${idInput}`
                );
                this.fullInput = data;
                /* (this.entrada.createAt = moment(
                    this.entrada.createAt,
                    "DD/MM/YYYY"
                ).format("YYYY-MM-DD")),
                    console.log("entrada", data); */
            } catch (error) {}
            finally{
                this.setLoading(false)
            }
        },
    },
};
</script>
