<template>
    <v-container fluid>
        <title-component
            title="Salidas"
            pathCreate="nuevaSalida"
            roleCreate="outputedit"
        ></title-component>

        <filter-component
            @search="search"
            @clearFilters="clearFilters"
        ></filter-component>

        <v-row>
            <v-col>
                <table-component
                    :headers="[
                        {
                            text: 'Lote',
                            align: 'start',
                            sortable: false,
                            value: 'input.lot',
                        },
                        { text: 'producto', value: 'input.product.name' },
                        { text: 'Total', value: 'weight' },
                        { text: 'Cliente', value: 'client.name' },
                        { text: 'Fecha', value: 'createAt' },
                        { text: 'Acciones', value: 'actions', width: '180px' },
                    ]"
                    :params="{
                        actualPage: 1,
                        take: 20,
                        search: params.search,
                    }"
                    :items="items"
                    editPathName="editarSalida"
                    roleEdit="outputedit"
                >
                </table-component>
            </v-col>
        </v-row>
        <infinite-loading
            ref="infiniteLoading"
            @infinite="getInfiniteItems"
            class="mt-5"
        >
            <div slot="no-more">No hay más elementos</div>
        </infinite-loading>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import TableComponent from "@/components/TableComponent.vue";

export default {
    name: "Salidas",
    components: {
        TitleComponent,
        FilterComponent,
        TableComponent,
    },
    data() {
        return {
            query: "/outputs",
            sheet: false,
            items: [],
            params: {
                actualPage: 1,
                take: 20,
                search: "",
            },
        };
    },
    methods: {
        async getItems() {
            const { data } = await this.$http.get(this.query, {
                params: this.params,
            });

            this.items = this.items.concat(data);
            return data;
        },
        async getInfiniteItems($state) {
            try {
                var items = await this.getItems();
                $state.loaded();
                if (items.length == 0) $state.complete();
                else this.params.actualPage++;
            } catch (error) {}
        },
        async search() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.items = [];
                this.params.actualPage = 1;
                /* await this.getItems();
                this.params.actualPage++; */
            } catch (error) {}
        },
        async clearFilters() {
            try {
                //Resetea el componente de scroll infinito
                this.$refs.infiniteLoading.stateChanger.reset();
                this.items = [];
                this.params = {
                    actualPage: 1,
                    take: 20,
                    search: "",
                };
            } catch (error) {}
        },
    },
};
</script>
