<template>
    <v-container fluid>
        <!-- <title-component :title="productName"></title-component> -->
        <form-salidas :item="salida" @refreshOutput="getOutput"></form-salidas>
    </v-container>
</template>

<script>
/* import TitleComponent from "@/components/Title.vue"; */
import FormSalidas from "@/views/salidas/Form.vue";
import moment from 'moment';

export default {
    components: {
        /* TitleComponent, */
        FormSalidas,
    },
    data() {
        return {
            salida: {},
            /* productName: null */
        }
    },
    mounted() {
        this.getOutput();
    },
    methods: {
        async getOutput() {
            try {
                const { data } = await this.$http.get(
                    `/outputs/${this.$route.params.id}`
                );
                this.salida = data;
                /* this.productName = data.input.product.name; */
                // console.log(this.salida.createAt);
                this.salida.createAt = moment(this.salida.createAt, 'DD/MM/YYYY').format("YYYY-MM-DD")
                // console.log(this.salida.createAt);
            } catch (error) {}
        },
    }
};
</script>

<style>
</style>