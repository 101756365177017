var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-component',{attrs:{"active":_vm.active,"max-width":"1400px"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('v-card',{staticClass:"mt-5"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Entradas disponibles ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('table-component',{attrs:{"headers":[
                    {
                        text: 'Lote',
                        align: 'start',
                        sortable: false,
                        value: 'lot',
                    },
                    { text: 'producto', value: 'product.name' },
                    { text: 'Total', value: 'weight' },
                    { text: 'Restante', value: 'remaining' },
                    { text: '€/Kg.', value: 'price' },
                    {
                        text: 'Proveedor',
                        value: 'provider.companyName',
                    },
                    {
                        text: 'Estado',
                        value: 'inputState.name',
                    },
                    { text: 'Fecha', value: 'createAt' },
                    { text: 'Acciones', value: 'actions' },
                ],"items":_vm.inputs,"selectable":true,"selectedItems":_vm.input.composition},on:{"selectButtonItem":_vm.addInputMovement,"putToUse":_vm.putStateItemInUse}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }