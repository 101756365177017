var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('title-component',{attrs:{"title":"Salidas","pathCreate":"nuevaSalida","roleCreate":"outputedit"}}),_c('filter-component',{on:{"search":_vm.search,"clearFilters":_vm.clearFilters}}),_c('v-row',[_c('v-col',[_c('table-component',{attrs:{"headers":[
                    {
                        text: 'Lote',
                        align: 'start',
                        sortable: false,
                        value: 'input.lot',
                    },
                    { text: 'producto', value: 'input.product.name' },
                    { text: 'Total', value: 'weight' },
                    { text: 'Cliente', value: 'client.name' },
                    { text: 'Fecha', value: 'createAt' },
                    { text: 'Acciones', value: 'actions', width: '180px' },
                ],"params":{
                    actualPage: 1,
                    take: 20,
                    search: _vm.params.search,
                },"items":_vm.items,"editPathName":"editarSalida","roleEdit":"outputedit"}})],1)],1),_c('infinite-loading',{ref:"infiniteLoading",staticClass:"mt-5",on:{"infinite":_vm.getInfiniteItems}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("No hay más elementos")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }