<template>
    <v-container fluid>
        <title-component title="Editar usuario"></title-component>
        <form-usuario @doSave="save" :item="usuario"></form-usuario>
        <form-change-password @doSave="savePass" :item="usuario"></form-change-password>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormUsuario from "./Form"
import FormChangePassword from "./FormChangePassword"

import { mapMutations } from "vuex";
export default {
    components: {
        TitleComponent,
        FormUsuario,
        FormChangePassword
    },
    data() {
        return {
            usuario: {},
        };
    },
    computed: {
        
    },
    mounted() {
        this.getUser();
    },
    methods: {
        ...mapMutations(["setLoading"]),
        async getUser() {
            try {
                const { data } = await this.$http.get(
                    `/users/${this.$route.params.id}`
                );
                var tmpRolesArr = []
                data.roles.forEach(rol => {
                    tmpRolesArr.push(rol.id)
                });
                data.roles = tmpRolesArr;
                this.usuario = data;
                // console.log("user", data);
            } catch (error) {}
        },
        async save(form) {
            // console.log("save edit");
            try {
                const {data} = await this.$http.put(`/users/${this.$route.params.id}`, form);
                console.log("save", data);
                this.$toast.success("Guardado correctamente");
            } catch (error) {}
            finally {
                this.setLoading(false);
            }
        },
        async savePass(form){
            try {
                const {data} = await this.$http.put(`/users/user-pass/${this.$route.params.id}`, form);
                console.log("save", data);
                this.$toast.success("Guardado correctamente");
            } catch (error) {
                
            } finally {
                this.setLoading(false);
            }
        }
    },
};
</script>

<style>
</style>