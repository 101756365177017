<template>
    <v-container fluid>
        <title-component title="Nuevo producto"></title-component>
        <form-productos></form-productos>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/Title.vue";
import FormProductos from "@/views/productos/Form.vue";

export default {
    name: "NuevoProducto",
    components: {
        TitleComponent,
        FormProductos,
    },
};
</script>

<style>
</style>