<template>
    <v-col cols="12" md="12">
        <search-inputs
            :active="activeSearch"
            :entrada="form"
            :product="form.product && form.product.id"
            @close="activeSearch = false"
        ></search-inputs>

        <v-card outlined>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Datos de la entrada</v-toolbar-title>
            </v-toolbar>
            <v-form @submit.prevent="submit">
                <v-card-text>
                    <!-- <p>form invalid== {{ $v.form.$invalid }}</p>
                    <p>total invalid=={{ $v.totalWeight.$invalid }}</p> -->
                    <!-- <p>{{ form }}</p> -->
                    <v-row>
                        <v-col
                            cols="12"
                            md="4"
                            v-for="(pt, i) in itemsProductTransformations"
                            :key="i"
                        >
                            <v-btn
                                @click="changeProductTransformation(pt.id)"
                                block
                                :color="
                                    form.productTransformation == pt.id
                                        ? 'success'
                                        : 'primary'
                                "
                                elevation="0"
                            >
                                <v-icon class="mr-2"> mdi-plus </v-icon>
                                {{ pt.name }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <custom-select-component
                                v-model="$v.form.provider.$model"
                                buttonText="Seleccionar proveedor"
                                itemText="companyName"
                                itemId="id"
                                :items="providers"
                                :disabled="!form.productTransformation"
                                @change="clearProduct(); changeProvider()"
                            >
                            </custom-select-component>
                        </v-col>
                        <v-col cols="12" md="6">
                            <custom-select-component
                                v-model="$v.form.product.$model"
                                buttonText="Seleccionar producto"
                                itemText="name"
                                itemId="id"
                                :items="products"
                                :disabled="!form.provider"
                                @change="changeProduct"
                            >
                            </custom-select-component>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-menu
                                ref="menuFecha"
                                v-model="menuFecha"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        outlined
                                        label="Fecha"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="createAtErrors"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="$v.form.createAt.$model"
                                    @change="convertDate"
                                    @input="menuFecha = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                            <custom-autocomplete-component
                                v-model="$v.form.inputState.$model"
                                outlined
                                query="/input-states"
                                label="Estados"
                                item-text="name"
                                item-value="id"
                                :error-messages="inputStateErrors"
                            ></custom-autocomplete-component>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                autocomplete="off"
                                outlined
                                v-model="$v.form.lot.$model"
                                label="Nº Lote*"
                                :error-messages="lotErrors"
                                @input="changeLot"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                autocomplete="off"
                                outlined
                                v-model="$v.form.weight.$model"
                                label="Cantidad (g)*"
                                type="number"
                                min="1"
                                @input="totalWeight = parseInt(form.weight)"
                                :error-messages="weightErrors"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field
                                autocomplete="off"
                                outlined
                                v-model="$v.form.price.$model"
                                label="Precio compra*"
                                type="number"
                                :error-messages="priceErrors"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                autocomplete="off"
                                outlined
                                v-model="$v.form.salePrice.$model"
                                label="Precio venta"
                                type="number"
                                :error-messages="salePriceErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- listado de entradas disponibles -->
                    <!-- Composición de productos -->
                    <v-row v-if="mezclaOMolienda">
                        <v-col>
                            <v-card outlined class="mt-5">
                                <v-toolbar color="primary" dark flat>
                                    <v-toolbar-title
                                        >Composición</v-toolbar-title
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn outlined @click="activeSearch = true"
                                        >Buscar entradas</v-btn
                                    >
                                </v-toolbar>
                                <div v-if="form.composition.length">
                                    <v-card-text>
                                        <v-row
                                            v-for="(item, index) in $v.form
                                                .composition.$each.$iter"
                                            :key="index"
                                        >
                                        <!-- {{item}} -->
                                            <v-col
                                                ><p class="text-h5 mt-2">
                                                    {{ item.$model.input.lot }}
                                                </p></v-col
                                            >
                                            <v-col
                                                ><p class="text-h5 mt-2">
                                                    {{
                                                        item.$model.input
                                                            .product.name
                                                    }}
                                                </p></v-col
                                            >
                                            <v-col>
                                                <v-text-field
                                                    required
                                                    label="Cantidad"
                                                    type="number"
                                                    v-model="item.weight.$model"
                                                    :key="item.$model.id"
                                                    :error-messages="
                                                        grindingErrors(item)
                                                    "
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    color="error"
                                                    x-large
                                                    elevation="0"
                                                    @click="
                                                        removeItem(item, index)
                                                    "
                                                    >Eliminar</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row v-if="mezclaOMolienda">
                        <!-- totalWeight -->
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="3">
                            <v-text-field
                                autocomplete="off"
                                outlined
                                required
                                readonly
                                v-model="$v.totalWeight.$model"
                                label="Cantidad total"
                                min="1"
                                :error-messages="totalWeightErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- <composition-table-component
                            :inputs="inputs"
                            :composition="form.grindingMovements"
                            @removeFromComposition="removeFromComposition"
                        ></composition-table-component> -->
                    <!-- </v-row> -->
                </v-card-text>
                <v-card-actions v-if="haveRole('inputedit')">
                    <v-btn
                        v-if="$v.form.id.$model && !form.deleted"
                        class="ml-4"
                        color="error"
                        elevation="0"
                        :loading="loading"
                        @click="remove"
                        >Eliminar</v-btn
                    >
                    <v-btn
                        v-if="$v.form.id.$model && form.deleted"
                        class="ml-4"
                        color="info"
                        elevation="0"
                        :loading="loading"
                        @click="restore"
                        >Recuperar</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="$v.$invalid"
                        class="mr-4"
                        color="primary"
                        elevation="0"
                        type="submit"
                        :loading="loading"
                        >Guardar</v-btn
                    >
                </v-card-actions>
            </v-form>

            <!-- <p>{{ form }}</p> -->
            <!-- <p>invalid {{ $v.$invalid }}</p> -->
            <!-- <p>dirty {{ $v.$anyDirty }}</p> -->
            <!-- <p>{{ $v }}</p> -->
            <!-- <p>{{ totalWeight }}</p> -->
            <!-- <p>{{ form.grindingMovements.length }}</p> -->
            <!-- <p>{{ form.createAt }}</p> -->
            <!-- <p>{{ date }}</p> -->
        </v-card>
    </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";

import CustomAutocompleteComponent from "@/components/CustomAutocompleteComponent";
import CustomSelectComponent from "@/components/CustomSelectComponent";

/* import ItemCompositionForm from "./ItemCompositionForm.vue"; */
import SearchInputs from "./SearchInputs.vue";

//import CompositionTableComponent from "@/components/CompositionTableComponent";

import { validateInteger, validatePrice } from "../../validations";

import { mapMutations, mapGetters } from "vuex";

/* const menorQue = (val, vm) => {
    console.log("me", val, vm);
    return val <= vm?.input?.remaining
} */

export default {
    name: "FormularioAlmacen",
    mixins: [validationMixin],
    components: {
        CustomSelectComponent,
        CustomAutocompleteComponent,
        /* ItemCompositionForm, */
        SearchInputs,
        /* TableComponent, */
        //CompositionTableComponent,
    },
    props: {
        item: Object,
    },
    validations: {
        form: {
            id: {},
            provider: { required },
            product: { required },
            createAt: { required },
            inputState: { required },
            lot: {
                required,
                checkLot() {
                    return this.validLot;
                },
            },
            weight: {
                required,
                minValue: minValue(1),
                unitsPattern(weight) {
                    return validateInteger(weight);
                },
            },
            price: {
                required,
                unitPricePattern(price) {
                    return validatePrice(price);
                },
            },
            salePrice: {
                unitPricePattern(salePrice) {
                    return validatePrice(salePrice);
                },
            },
            composition: {
                $each: {
                    weight: {
                        required: requiredIf(function () {
                            this.mezclaOMolienda;
                        }),
                        minValue: minValue(1),
                        remainingExceded(val, vm) {
                            // console.log("me", val, vm);
                            // console.log(
                            //     "v====",
                            //     this.$v.form.composition.$each.$dirty
                            // );
                            if (
                                this.$v.form.composition.$each.$anyDirty
                            ) {
                                return val <= vm?.input?.remaining;
                            } else {
                                return true;
                            }
                            /* console.log("this.$v",this?.$v?.form?.grindingMovements?.$each);
                            var arr = this.$v.form.grindingMovements.$each.$iter;
                            console.log("arr",arr);
                            var m = arr?.shift()//.find((move) => move.$model.id == vm.id);
                            console.log("mmmm", m);
                            if(!m.weight.$dirty){
                                return false;
                            } */
                            //return val <= vm?.input?.remaining
                        },
                    },
                },
                /* $each: {
                    weight: {  
                        required, */
                /* minValue: minValue(1), */
                /* isValid(value, movement) {
                            if(value != undefined && movement != undefined){

                                console.log("isvalid==", value, movement);
                                var movObj = this.form.grindingMovements.find(move => move.id == movement.id);
                                console.log("check2 ===", movObj.input.remaining , value);
                                return movObj.input.remaining >= value;
                            }
                        }  */
                /* if(obj != undefined && obj.input != undefined){

                                console.log("check ===", obj);
                                var movement = this.form.grindingMovements.find(move => move.id == obj.id);
                                console.log("check2 ===", movement.input.remaining, obj.weight);
                                return movement.input.remaining < obj.weight;
                            } */
                /* },
                } */
            },
        },
        totalWeight: {
            required: requiredIf(function () {
                this.mezclaOMolienda;
            }),
            sameAsWeight(val) {
                console.log("same as", val);
                if (!this.mezclaOMolienda) {
                    return true;
                }
                var cantidadTotalMovimientos = 0;
                this.form.composition.forEach((move) => {
                    cantidadTotalMovimientos += parseInt(move.weight);
                });
                // console.log("ccc", cantidadTotalMovimientos);
                return this.totalWeight == cantidadTotalMovimientos;
                //return true;
            },
        },
    },
    data() {
        return {
            loading: false,
            itemsProductTransformations: [],
            activeSearch: false,
            queryProducts: null,
            existLot: false,
            form: {
                provider: null,
                product: null,
                createAt: new Date().toISOString().substr(0, 10),
                inputState: 1,
                lot: "",
                weight: "",
                price: "",
                salePrice: null,
                productTransformation: null,
                composition: [],
            },
            totalWeight: null,
            inputs: [],
            providers: [],
            products: [],
            menuFecha: false,
            date: moment(new Date()).format("DD/MM/YYYY"),
        };
    },
    watch: {
        item() {
            // console.log("item", this.item);
            this.form = {
                id: this.item.id,
                productTransformation: this.item.productTransformation.id,
                provider: this.item.provider,
                product: this.item.product,
                createAt: this.item.createAt,
                inputState: this.item.inputState.id,
                lot: this.item.lot,
                weight: this.item.weight,
                price: this.item.price,
                salePrice: this.item.salePrice,
                composition: this.item.composition,
                deleted: this.item.deleted
            }
            this.totalWeight = this.form.weight;
            this.date = moment(this.form.createAt).format("DD/MM/YYYY"),
            this.changeProvider()
            //this.getTransformationType(this.form.product.id);
            /* this.getInputs(this.form.product.id); */
        },
    },
    mounted() {
        this.getProductTransformations();
        this.getProviders();
    },
    computed: {
        ...mapGetters("auth", ["haveRole"]),
        validLot() {
            return !this.existLot;
        },
        mezclaOMolienda() {
            return this.grinding || this.mix;
        },
        normal() {
            return this.form.productTransformation == 1;
        },
        grinding() {
            return this.form.productTransformation == 2;
        },
        mix() {
            return this.form.productTransformation == 3;
        },
        providerErrors() {
            const errors = [];
            if (!this.$v.form.provider.$dirty) return errors;
            !this.$v.form.provider.required && errors.push("Obligatorio");
            return errors;
        },
        productErrors() {
            const errors = [];
            if (!this.$v.form.product.$dirty) return errors;
            !this.$v.form.product.required && errors.push("Obligatorio");
            return errors;
        },
        createAtErrors() {
            const errors = [];
            if (!this.$v.form.createAt.$dirty) return errors;
            !this.$v.form.createAt.required && errors.push("Obligatorio");
            return errors;
        },
        inputStateErrors() {
            const errors = [];
            if (!this.$v.form.inputState.$dirty) return errors;
            !this.$v.form.inputState.required && errors.push("Obligatorio");
            return errors;
        },
        lotErrors() {
            const errors = [];
            if (!this.$v.form.lot.$dirty) return errors;
            !this.$v.form.lot.required && errors.push("Obligatorio");
            !this.$v.form.lot.checkLot && errors.push("El lote ya existe");
            return errors;
        },
        weightErrors() {
            const errors = [];
            if (!this.$v.form.weight.$dirty) return errors;
            !this.$v.form.weight.required && errors.push("Obligatorio");
            !this.$v.form.weight.unitsPattern &&
                errors.push("Debe introducir un número entero válido");
            return errors;
        },
        priceErrors() {
            const errors = [];
            if (!this.$v.form.price.$dirty) return errors;
            !this.$v.form.price.required && errors.push("Obligatorio");
            !this.$v.form.price.unitPricePattern &&
                errors.push("Debe introducir un precio válido");
            return errors;
        },
        salePriceErrors() {
            const errors = [];
            if (!this.$v.form.salePrice.$dirty) return errors;
            !this.$v.form.salePrice.unitPricePattern &&
                errors.push("Debe introducir un precio válido");
            return errors;
        },
        totalWeightErrors() {
            const errors = [];
            //if (!this.$v.totalWeight.$dirty) return errors;
            //!this.$v.totalWeight.required && errors.push("Obligatorio");
            !this.$v.totalWeight.sameAsWeight &&
                errors.push("Revise las cantidades");
            return errors;
        },
    },
    methods: {
        ...mapMutations(["setLoading"]),
        convertDate() {
            this.date = moment(this.form.createAt).format("DD/MM/YYYY");
        },
        async getProductTransformations() {
            const { data } = await this.$http.get(
                "/productTransformation/getAll"
            );
            this.itemsProductTransformations = data;
        },
        async getProviders() {
            const { data } = await this.$http.get(`/providers/getAll`);
            //console.log("proveedores======>",data);
            this.providers = data;
        },
        changeProductTransformation(id) {
            console.log("changeProductTransformation", id);
            this.form.productTransformation = id;
            this.form.provider = null;
            if (id == 2 || id == 3) {
                this.setAlmacenProvider();
            }
            //this.getTransformationType();
            this.form.product = null;
            this.products = [];
        },
        setAlmacenProvider() {
            this.providers.forEach((provider) => {
                //console.log("Provider",provider);
                if (provider.companyName.toUpperCase() == "ALMACEN") {
                    this.form.provider = provider;
                    //duplica la petición
                    this.changeProvider(this.form.provider);
                }
            });
        },
        clearProduct(){
            console.log("clear product");
            this.form.product = null;
        },
        async changeProvider() {
            // console.log("changeProvider");
            //this.form.product = null;
            const { data } = await this.$http.get(
                `/products/getAllByProvider/${this.form.provider.id}`,
                {
                    params: {
                        productTransformation: this.form.productTransformation
                    }
                }
            );
            this.products = data;
            if (!this.products.length) {
                this.$toast.warning(
                    "El proveedor no tiene productos asociados"
                );
            }
        },

        grindingErrors(move) {
            // console.log("Move", move);
            const errors = [];
            if (!move.weight.$dirty) return errors;
            !move.weight.required && errors.push("Obligatorio");
            !move.weight.minValue && errors.push("Cantidad mínima 1");
            !move.weight.remainingExceded &&
                errors.push(
                    "Restante: " +
                        move.$model.input.remaining +
                        " g"
                );
            return errors;
        },
        /* changeQueryProduct(query) {
            this.queryProducts = query;
        }, */
        
        
        

        async changeProduct(idProduct) {
            var params = {
                idInput: this.form.id,
            };
            const { data } = await this.$http.get(
                `/inputs/getlot/${idProduct}`,
                {
                    params: params,
                }
            );
            this.form.lot = data;
        },
        async changeLot() {
            //comprobar si existe el numero de lote
            var params = {
                idInput: this.form.id,
                numLot: this.form.lot,
            };
            const { data } = await this.$http.get(`/inputs/exist/lot`, {
                params: params,
            });
            this.existLot = data;
        },
        /* async getInputs(idProduct) {
            //Obtenemos todas las entradas en uso filtradas por producto
            const { data } = await this.$http.get(
                `/inputs/getInputsCompositionsInUseFilterByProduct/${idProduct}`
            );
            this.inputs = data;
        }, */
        /* async getTransformationType() {
            const type = this.form.productTransformation.id;
            switch (type) {
                case 1:
                    this.queryProducts = "/products/getAllNormal";
                    break;
                case 2:
                    this.queryProducts = "/products/getAllGround";
                    break;
                case 3:
                    this.queryProducts = "/products/getAllMix";
                    break;
                default:
                    this.queryProducts = "/products?take=9999999";
                    break;
            }
        }, */
        //composition items
        async removeItem(item, index) {
            // console.log("borrar item", item, index);
            try {
                this.form.composition.splice(index, 1);
            } catch (error) {
                console.log(error);
            }
        },
        //fin composition items
        async submit() {
            // console.log("1");
            //this.$v.$touch();
            if (!this.$v.$invalid) {
                // console.log("2");
                //this.setLoading(true);
                try {
                    if (!this.form.id) {
                        const { data } = await this.$http.post(
                            `/inputs`,
                            this.form
                        );
                        // console.log("input", data);
                        this.$router.push({
                            name: "editarEntrada",
                            params: { id: data.id },
                        });
                    } else {
                        const { data } = await this.$http.put(
                            `/inputs/${this.$route.params.id}`,
                            this.form
                        );
                        console.log(data);
                        this.$emit("refreshInput");
                    }
                    this.$toast.success("Guardado correctamente");
                } catch (error) {
                } finally {
                    //this.setLoading(false);
                }
            }
        },
        async remove() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.delete("/inputs/" + this.form.id);
                    this.$router.push({ name: "almacen" });
                    this.$toast.success("Eliminado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
        async restore() {
            this.setLoading(true);
            try {
                if (this.form.id) {
                    await this.$http.get("/inputs/restore/" + this.form.id);
                    this.form.deleted = false;
                    this.$toast.success("Recuperado correctamente");
                }
            } catch (error) {
                // console.log("ee", error);
            } finally {
                this.setLoading(false);
            }
        },
    },
};
</script>

<style>
</style>