<template>
    <v-autocomplete
        v-bind="{ ...$props, ...$attrs }"
        :items="items"
        v-on="$listeners"
    ></v-autocomplete>
</template>

<script>
export default {
    props: {
        query: {
            type: String,
        },
        /* value: {
            type: [ String, Number, Array ]
        } */
    },
    /* model: {
        prop: "value",
        event: "change"
    }, */
    data() {
        return {
            items: [],
        };
    },
    watch: {
        query() {
            this.init()
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.query) {
                const { data } = await this.$http.get(this.query);
                //console.log("Query " + this.query, data);
                this.items = data;
            }
        },
    },
};
</script>